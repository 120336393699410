import React, { useState, useEffect, useContext } from "react";
import editsvg from "../../assets/img/edit.svg";
import { ReactSVG } from "react-svg";
import Tooltip from "@mui/material/Tooltip";
import { showNotificationToast } from "../../helpers/Messages";
import AuthContext from "../auth/AuthContext";

const ImagePosition = ({ positionData, imageId }) => {
  const authContext = useContext(AuthContext);

  const [data, setData] = useState(positionData);
  const [editMode, setEditMode] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [hoverContainer, setHoverContainer] = useState(false);

  const isBlueBlock = (position) => {
    return data?.image_position === position;
  };

  const isNextPosition = (position) => {
    return data?.next_position === position;
  };

  const isPreviousPosition = (position) => {
    return data?.previous_position === position;
  };

  const toggleEditMode = () => setEditMode(!editMode);

  const handleItemHover = (positionId) => {
    setHoveredItemId(positionId);
  };

  const selectPosition = async (positionId) => {
    if (!editMode) return;

    const data = {
      position: positionId,
    };

    try {
      const response = await window.apiHelper.getUpdatePosition(imageId, data);

      if (response) {
        showNotificationToast("Image position updated successfully!");
      }
    } catch (e) {
      console.log(e);
      showNotificationToast("Error, please try again!");
    }
    setData({ image_position: positionId });
    setEditMode(false);
  };

  useEffect(() => {
    setData(positionData);
  }, [positionData]);

  return (
    <div
      className={`position-container ${editMode ? "edit-mode" : ""}`}
      onMouseOver={() => setHoverContainer(true)}
      onMouseOut={() => setHoverContainer(false)}
    >
      <div className="rows">
        {editMode && <div className="edit-text">Edit</div>}
        {authContext.permissions.includes("can_change_drone_direction") &&
          hoverContainer && (
            <Tooltip title="Edit image position" placement="top" followCursor>
              {" "}
              <ReactSVG
                src={editsvg}
                className="position-edit-icon"
                style={{ width: "4px", height: "4px" }}
                onClick={toggleEditMode}
              />
            </Tooltip>
          )}

        <div className="singleRow">
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position7 ${
                  isBlueBlock(7) || (editMode && hoveredItemId === 7)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(7)}
                onMouseOver={() => handleItemHover(7)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(7) && (
              <div className="positionTop-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(7) && (
                <div className="positionTop-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position0 ${
                  isBlueBlock(0) || (editMode && hoveredItemId === 0)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(0)}
                onMouseOver={() => handleItemHover(0)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(0) && (
              <div className="positionTop-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(0) && (
                <div className="positionTop-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position1 ${
                  isBlueBlock(1) || (editMode && hoveredItemId === 1)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(1)}
                onMouseOver={() => handleItemHover(1)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(1) && (
              <div className="positionTop-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(1) && (
                <div className="positionTop-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
        </div>
        <div className="singleRow">
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position6 ${
                  isBlueBlock(6) || (editMode && hoveredItemId === 6)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(6)}
                onMouseOver={() => handleItemHover(6)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(6) && (
              <div className="positionLeft-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(6) && (
                <div className="positionLeft-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow-center position8 ${
                  isBlueBlock(8) || (editMode && hoveredItemId === 8)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(8)}
                onMouseOver={() => handleItemHover(8)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
          </div>
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position2 ${
                  isBlueBlock(2) || (editMode && hoveredItemId === 2)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(2)}
                onMouseOver={() => handleItemHover(2)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(2) && (
              <div className="positionRight-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(2) && (
                <div className="positionRight-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
        </div>
        <div className="singleRow">
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position5 ${
                  isBlueBlock(5) || (editMode && hoveredItemId === 5)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(5)}
                onMouseOver={() => handleItemHover(5)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(5) && (
              <div className="positionBottom-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(5) && (
                <div className="positionBottom-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position4 ${
                  isBlueBlock(4) || (editMode && hoveredItemId === 4)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(4)}
                onMouseOver={() => handleItemHover(4)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(4) && (
              <div className="positionBottom-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(4) && (
                <div className="positionBottom-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
          <div className="singleBlock">
            <div className="shadow">
              <div
                className={`arrow position3 ${
                  isBlueBlock(3) || (editMode && hoveredItemId === 3)
                    ? "blue"
                    : "white"
                }`}
                onClick={() => selectPosition(3)}
                onMouseOver={() => handleItemHover(3)}
                onMouseOut={() => handleItemHover(null)}
              />
            </div>
            {(isNextPosition(3) && (
              <div className="positionBottom-text">
                {positionData?.next_structure}
              </div>
            )) ||
              (isPreviousPosition(3) && (
                <div className="positionBottom-text">
                  {positionData?.previous_structure}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePosition;

import { Months, PROD_API_URL, STAGE_API_URL, TEST_API_URL } from "./Constants";

export const getDateFormatted = (data) => {
  const date = new Date(Date.parse(data));
  return (
    date.getDate() + " " + Months[date.getMonth()] + " " + date.getFullYear()
  );
};

export const getDateTimeFormatted = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleDateString(undefined, options);
};

export const detectEnvironment = () => {
  const currentURL = window.location.href;

  if (currentURL.includes("https://detect-inspections.com/")) {
    return PROD_API_URL;
  } else if (currentURL.includes("https://test.detect-inspections.com/")) {
    return TEST_API_URL;
  } else {
    return STAGE_API_URL;
  }
};

export const chartData1 = (data) => {
  return {
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgba(221, 44, 0, 0.8)",
          "rgba(249, 168, 37, 0.8)",
          "rgba(102, 187, 106, 0.8)",
        ],
        borderColor: [
          "rgba(221, 44, 0, 1)",
          "rgba(249, 168, 37, 1)",
          "rgba(102, 187, 106, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

export const emptyChartData = (data) => {
  return {
    datasets: [
      {
        data: data,
        backgroundColor: ["rgb(235,235,235,0.8)"],
        borderColor: ["rgb(235,235,235,1)"],
        borderWidth: 1,
      },
    ],
  };
};

export const chartData2 = (data) => {
  return {
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgba(102, 187, 106, 0.8)",
          "rgba(204, 204,204, 0.8)",
        ],
        borderColor: ["rgba(102, 187, 106, 1)", "rgba(204, 204,204, 1)"],
        borderWidth: 1,
      },
    ],
  };
};

export const chartData3 = (data) => {
  return {
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgba(249, 168, 37, 0.8)",
          "rgba(102, 187, 106, 0.8)",
          "rgba(204, 204, 204, 0.8)",
          "rgba(221, 44, 0, 0.8)",
          "#0a4273",
          "rgba(188, 119, 66, 0.8)",
        ],
        borderColor: [
          "rgba(249, 168, 37, 1)",
          "rgba(102, 187, 106, 1)",
          "rgba(204, 204, 204, 1)",
          "rgba(221, 44, 0, 1)",
          "#0a4273",
          "rgba(188, 119, 66, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

export const getTimeDifference = (notificationTime) => {
  const notificationTimeObject = new Date(notificationTime);
  const difference = new Date() - notificationTimeObject;
  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hrs ago`;
  } else if (minutes > 0) {
    return `${minutes} mins ago`;
  } else {
    return `${seconds} secs ago`;
  }
};

export const formatDateTime = (datetime, shortForm) => {
  const dateRaw = Date.parse(datetime);
  let formattedDate = "-";
  if (!isNaN(dateRaw)) {
    const date = new Date(dateRaw);

    const months = shortForm
      ? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]
      : [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

    const dateFormatted =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    const timeFormatted =
      date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0");

    formattedDate = dateFormatted + ", " + timeFormatted;
  }

  return formattedDate;
};

export const severityToText = (severity) => {
  switch (severity) {
    case 1:
      return "good";
    case 2:
      return "low";
    case 3:
      return "medium";
    case 4:
      return "high";
    default:
      return "good";
  }
};

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};

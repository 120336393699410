import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

const withNavigate = Component => props => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

class ReportInspectionDiagram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                high: true,
                medium: true,
                low: true
            }
        };

        this.onCardClick = this.onCardClick.bind(this);
    }


    getPriorityLabel(index) {
        switch (index) {
            case 4: return 'high';
            case 3: return 'medium';
            case 2: return 'low';
            default: return 'good';
        }
    }


    onCardClick(e) {
        e.preventDefault();

        this.props.navigate('/reports/'+this.props.data.id);
    }


    render() {
        return (
            <div className="report-inspection-diagram-cover">
                <ul className="diagram-togglers">
                    {['high', 'medium', 'low'].map((priority, index) =>
                        <li key={index} onClick={() => {
                            let filters = this.state.filters;
                            filters[priority] = !filters[priority];
                            this.setState({filters: filters});
                        }} className={priority+' '+((this.state.filters[priority])?('active'):(''))}></li>
                    )}
                </ul>

                <h5>Inspection Diagram</h5>

                <div className="report-inspection-diagram">
                    {this.props.structuresPriorities && Object.entries(this.props.structuresPriorities).map(([structure, priorities]) => {
                        let priorityClasses = [];
                        Object.entries(priorities).map(([priority, appears]) => {
                            if (appears && this.state.filters[priority] !== false) {
                                priorityClasses.push(priority);
                            }
                        });

                        return (<div
                            key={structure}
                            className={priorityClasses.join(' ')}
                        ></div>);
                    })}
                </div>
            </div>
        );
    }
}

export default withNavigate(ReportInspectionDiagram);

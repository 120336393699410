import React, { Component } from 'react';
import {Link, Navigate} from "react-router-dom";

class AuthPageRegistrationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            name: '',
            phone: '',
            formSubmitted: false
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onRegistration = this.onRegistration.bind(this);
    }


    onEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }


    onNameChange(e) {
        this.setState({
            name: e.target.value
        });
    }


    onPhoneChange(e) {
        this.setState({
            phone: e.target.value
        });
    }


    onRegistration(e) {
        e.preventDefault();
        console.log('Trying to register...');
    }


    render() {
        return (
            <>
                {this.state.formSubmitted && (
                    <Navigate to="/" replace={true}/>
                )}

                <form action="/">
                    <h1>Contact Us</h1>
                    <p className="subheader"><Link to='/auth'>Back to login »</Link></p>

                    <div className="form-group">
                        <label htmlFor="email-field">Email</label>
                        <input type="text" name="email-field" className="form-control" id="email-field" onChange={this.onEmailChange}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="name-field">Name</label>
                        <input type="text" name="name-field" className="form-control" id="name-field" onChange={this.onNameChange}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone-field">Phone</label>
                        <input type="text" name="phone-field" className="form-control" id="phone-field" onChange={this.onPhoneChange}/>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-warning" onClick={this.onRegistration}>Send a message</button>
                    </div>
                </form>
            </>
        );
    }
}

export default AuthPageRegistrationForm;

import React, { Component } from 'react';
import {Layer, Source} from "react-map-gl";
import {heatmapLayer} from "../../helpers/HeatmapLayer.ts";

class HeatMapSource extends Component {
    constructor(props) {
        super(props);
    }


    createGeoJSONData() {
        let data = {
            "type": "FeatureCollection",
            "features": []
        };

        if (this.props.inspections) {
            for (const inspection of this.props.inspections) {
                inspection.structure_data.forEach((structureData) => {
                    data.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [structureData[1], structureData[0]]
                        },
                        "properties": {
                            "value": structureData[2] * 10,
                        }
                    });
                });
            }
        }

        return data;
    }


    render() {
        return (
            <Source type="geojson" data={this.createGeoJSONData()}>
                <Layer {...heatmapLayer} />
            </Source>
        );
    }
}

export default HeatMapSource;

import React, { createContext, useContext, useState, useEffect } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [org, setOrg] = useState(() => {
    const storedOrg = localStorage.getItem("org");
    return storedOrg ? storedOrg : "";
  });

  const [requestData, setRequestData] = useState(() => {
    const storedrequestData = JSON.parse(localStorage.getItem("requestData"));
    return storedrequestData ? storedrequestData : [];
  });

  const setOrgAndPersist = (newOrg) => {
    setOrg(newOrg);
  };

  const setRequestDataAndPersist = (RequestData) => {
    setRequestData(RequestData);
  };

  useEffect(() => {
    localStorage.setItem("org", org);
  }, [org]);

  useEffect(() => {
    localStorage.setItem("requestData", JSON.stringify(requestData));
  }, [requestData]);

  return (
    <GlobalContext.Provider
      value={{
        org,
        setOrg: setOrgAndPersist,
        requestData,
        setRequestData: setRequestDataAndPersist,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  return useContext(GlobalContext);
};

export class GradientPolylineLayerPhantom {
    constructor() {
    }

    static create(inspection) {
        const layer = {
            id: 'grad-poly-layer-phantom-'+ inspection.request_id,
            inspectionId: inspection.request_id,
            beforeId: 'heatmap',
            type: 'line',
            maxzoom: 24,
            interactive: true,
            paint: {
                'line-color': 'rgba(255,0,0,0)',
                'line-width': 30,
            },
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            }
        };

        return layer;
    }
}
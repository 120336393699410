import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import previewSrc from './../../assets/img/map_preview_tmp.jpg';

const withNavigate = Component => props => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

class ReportCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.onDotsClick = this.onDotsClick.bind(this);
        this.onCardClick = this.onCardClick.bind(this);
    }


    getDateFormatted() {
        const date = new Date(Date.parse(this.props.data.start_date));
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }


    onDotsClick(e) {
        e.stopPropagation();
        this.props.onPopupToggle(e);
    }


    onCardClick(e) {
        e.preventDefault();

        this.props.navigate('/reports/'+this.props.data.id);
    }


    render() {
        const cardTitle = this.props.data.line_number+' / #'+this.props.data.id,
            cardDate = this.getDateFormatted();

        return (
            <div className="inspection-card" onClick={this.onCardClick}>
                <div className="card-preview"><img src={previewSrc} alt={'Map preview image'} /></div>
                <div className="card-info">
                    <h5>{cardTitle}</h5>
                    <p>{cardDate}</p>

                    <button className="d-none" onClick={this.onDotsClick}>
                        <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="1.5" cy="1.5" r="1.5" fill="#CCCCCC"/>
                            <circle cx="1.5" cy="7.5" r="1.5" fill="#CCCCCC"/>
                            <circle cx="1.5" cy="13.5" r="1.5" fill="#CCCCCC"/>
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}

export default withNavigate(ReportCard);

import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import ScaleLoader from "react-spinners/ScaleLoader";

const InfiniteScroll = ({
  children,
  maxHeight,
  fetchWorkordersList,
  loading,
  hasMore,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const simpleBarRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      const simpleBarElement = simpleBarRef.current?.getScrollElement();
      if (!simpleBarElement) return;

      const { scrollTop, scrollHeight, clientHeight } = simpleBarElement;

      const isAtBottom = scrollHeight - scrollTop <= clientHeight + 5;

      setScrollPosition(scrollTop);

      if (isAtBottom) {
        fetchMoreData();
      }
    }

    const simpleBarElement = simpleBarRef.current?.getScrollElement();
    if (!simpleBarElement) return;

    simpleBarElement.addEventListener("scroll", handleScroll);
    return () => simpleBarElement.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const fetchMoreData = () => {
    if (!loading && hasMore) {
      fetchWorkordersList();
    }
  };

  return (
    <SimpleBar
      style={{
        maxHeight: maxHeight,
        overflowX: "hidden",
        paddingRight: 13,
        marginRight: -13,
        textAlign: "center",
      }}
      autoHide={true}
      ref={simpleBarRef}
    >
      {children}
      {loading && (
        <div className="loadingText">
          <ScaleLoader loading={true} size={12} />
        </div>
      )}
      {/* {!loading && !hasMore && <p>No more data</p>} */}
    </SimpleBar>
  );
};

export default InfiniteScroll;

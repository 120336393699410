import * as React from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";

const InspectionsFilterDropdown = ({
  activeButtonSelected,
  toggleDropdownInspections,
}) => {
  const handleOptionSelect = (option) => {
    activeButtonSelected(option);
    toggleDropdownInspections();
  };
  return (
    <MenuList style={{ padding: "4px", color: "#000" }}>
      <MenuItem onClick={() => handleOptionSelect("Map")}>
        <ListItemText>Map</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleOptionSelect("Tracking")}>
        <ListItemText>Tracking</ListItemText>
      </MenuItem>
    </MenuList>
  );
};

export default InspectionsFilterDropdown;

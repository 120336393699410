import React, { useState, useEffect } from "react";

const Sending = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        return prevDots === "..." ? "" : prevDots + ".";
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return <div className="sending">Sending{dots}</div>;
};

export default Sending;

import React from "react";

const Divider = ({ size = "default" }) => {
  return (
    <div className="divider">
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.60289 4.25L3.76795 8C4.53775 9.33333 6.46225 9.33334 7.23205 8L9.39711 4.25C10.1669 2.91667 9.20467 1.25 7.66507 1.25L3.33494 1.25C1.79534 1.25 0.833084 2.91666 1.60289 4.25Z"
          stroke="rgba(39, 197, 155, 1)"
          strokeWidth="2"
        />
      </svg>
      <div className={`vertical-line-inspectionView-${size}`}></div>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_57_772" fill="white">
          <rect width="9" height="9" rx="1" />
        </mask>
        <rect
          width="9"
          height="9"
          rx="1"
          stroke="rgba(39, 197, 155, 1)"
          strokeWidth="4"
          mask="url(#path-1-inside-1_57_772)"
        />
      </svg>
    </div>
  );
};

export default Divider;

import React, { useState, useEffect } from "react";
import Loader from "./Loader";

const PopupListImage = ({
  isLoading,
  setIsLoading,
  selectedImageDetails,
  selectedItemId,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [start, setStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setIsLoading(true);
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  }, [selectedItemId]);

  const handleWheel = (e) => {
    e.preventDefault();
    const zoomChange = e.deltaY > 0 ? -0.1 : 0.1;
    setZoomLevel((prevZoomLevel) =>
      Math.min(Math.max(prevZoomLevel + zoomChange, 1), 5)
    );
    {
      zoomLevel === 1 && setPosition({ x: 0, y: 0 });
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStart({
      x: e.clientX - position.x * zoomLevel,
      y: e.clientY - position.y * zoomLevel,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = (e.clientX - start.x) / zoomLevel;
    const dy = (e.clientY - start.y) / zoomLevel;
    setPosition({
      x: dx,
      y: dy,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setPosition((prevPosition) => ({
      x: prevPosition.x,
      y: prevPosition.y,
    }));
  };

  const imagesDisplay = document.querySelector(".images-display");
  const imageDisplayed = document.querySelector(".image-displayed");

  if (imagesDisplay && imageDisplayed) {
    const parentWidth = imagesDisplay.clientWidth;
    const parentHeight = imagesDisplay.clientHeight;
    const aspectRatio =
      selectedImageDetails.height / selectedImageDetails.width;
    let height = parentWidth * aspectRatio;

    if (height > parentHeight) {
      height = parentHeight; // Limit height to parent height if it exceeds
    }

    imageDisplayed.style.height = `${height}px`;
  }
  return (
    <div
      className="images-display"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onWheel={handleWheel}
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
    >
      {isLoading && <Loader />}
      <img
        src={selectedImageDetails.file}
        alt="Image Alt Text"
        className={`image-displayed ${isLoading ? "hidden" : ""}`}
        onLoad={() => setIsLoading(false)} // Update state when image is loaded
        style={{
          transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
          transition: isDragging ? "none" : "transform 0.3s ease",
        }}
      />
    </div>
  );
};

export default PopupListImage;

import React, { Component } from 'react';
import {Link, Navigate} from "react-router-dom";

class AuthPageResetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            formSubmitted: false
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onReset = this.onReset.bind(this);
    }


    onEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }


    async onReset(e) {
        e.preventDefault();
        console.log('Trying to reset password...');

        let result = await window.appComponent.authHelper.reset(this.state.email);

        console.log('result', result);

        /*if (result) {
            window.location = '/';
        } else {
            this.setState({
                formError: true
            })
        }*/
    }


    render() {
        return (
            <>
                {this.state.formSubmitted && (
                    <>
                        <h1>A request been sent!</h1>

                        <p className="subheader">We've sent you an email with further instructions.</p>
                    </>
                )}

                {!this.state.formSubmitted && (
                    <form action="/">
                        <p className="subheader"><Link to='/auth'>« back to login</Link></p>

                        <h1>Reset your password</h1>

                        <div className="form-group">
                            <label htmlFor="email-field">Email</label>
                            <input type="text" name="email-field" className="form-control" id="email-field" onChange={this.onEmailChange}/>
                            {this.state.formError &&
                                <p className="hint">Wrong email</p>
                            }
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-warning" onClick={this.onReset}>Reset your password</button>
                        </div>
                    </form>
                )}
            </>
        );
    }
}

export default AuthPageResetForm;

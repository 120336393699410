import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

const OrderForm = (props) => {
  return (
    <Formik
      initialValues={{
        utilityProvider: "",
        //workOrder: '',
        lineNumber: "",
        segmentStart: "",
        segmentEnd: "",
        /*segments: [
                {
                    start: '',
                    end: ''
                }
            ],
            contactEmail: '',
            contactName: '',*/
        comments: "",
      }}
      validate={async (values) => {
        const errors = {};
        for (const field of [
          "utilityProvider",
          "lineNumber",
          "segmentStart",
          "segmentEnd" /*'segments', 'contactEmail', 'contactName'*/,
        ]) {
          if (!values[field]) {
            errors[field] = "Please, fill in this field.";
          }
        }

        //check if values.lineNumber exists and is in props.lineNumbers
        if (values.lineNumber) {
          let lineNumberFound = false;
          props.lineNumbers.forEach((item) => {
            if (item[1] === values.lineNumber) {
              lineNumberFound = true;
            }
          });
          if (!lineNumberFound) {
            errors.lineNumber = "Invalid Line Number.";
          }
        }

        if (values.segmentStart || values.segmentEnd) {
          let lineNumberID = "";
          props.lineNumbers.forEach((item) => {
            if (item[1] === values.lineNumber) {
              lineNumberID = item[0];
            }
          });

          let structures = await window.apiHelper.orderGetStructures(
            lineNumberID
          );
          if (structures) {
            structures = structures.map((item) => {
              return item[0];
            });
          }

          if (
            values.segmentStart &&
            structures.indexOf(values.segmentStart) === -1
          ) {
            errors.segmentStart = "Invalid Segment.";
          }

          if (
            values.segmentEnd &&
            structures.indexOf(values.segmentEnd) === -1
          ) {
            errors.segmentEnd = "Invalid Segment.";
          }
        }

        /*if (values.contactEmail &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.contactEmail)
            ) {
                errors.contactEmail = 'Invalid email address.';
            }*/

        return errors;
      }}
      onSubmit={async (values, actions) => {
        let userEmail = localStorage.getItem("user_email");
        let futureFields = {
          org: values.utilityProvider,
          user: userEmail,
          line_number: values.lineNumber,
          start_structure: values.segmentStart,
          end_structure: values.segmentEnd,
          requirements: values.comments,
        };

        let results = await window.apiHelper.orderCreate(futureFields);

        let errors = {};
        if (results.error_type && results.error_type === "ValidationError") {
          results.errors.forEach((item) => {
            if (item.field === "line_number") {
              errors.lineNumber = item.message;
            }
            if (item.field === "start_structure") {
              errors.segmentStart = item.message;
            }
            if (item.field === "end_structure") {
              errors.segmentEnd = item.message;
            }
          });

          actions.setErrors(errors);
        } else {
          console.log(results);
          props.onSuccess();
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form className="form-centered" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="email-field">Utility Provider</label>
                <Field
                  as="select"
                  name="utilityProvider"
                  className="form-control"
                >
                  <option value="" disabled={true}>
                    Select...
                  </option>
                  {props.utilityProviders &&
                    props.utilityProviders.map((provider, index) => {
                      return (
                        <option key={index} value={provider.name}>
                          {provider.name}
                        </option>
                      );
                    })}
                </Field>
                <p className="hint">
                  {errors.utilityProvider &&
                    touched.utilityProvider &&
                    errors.utilityProvider}
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="lineNumber-field">Line Number</label>
                <Field
                  name="lineNumber"
                  type="text"
                  className="form-control"
                  id="lineNumber-field"
                  value={values.lineNumber}
                />
                <p className="hint">
                  {errors.lineNumber && touched.lineNumber && errors.lineNumber}
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="segmentStart-field">Start Structure</label>
                <Field
                  name="segmentStart"
                  type="text"
                  className="form-control"
                  id="segmentStart-field"
                  value={values.segmentStart}
                />
                <p className="hint">
                  {errors.segmentStart &&
                    touched.segmentStart &&
                    errors.segmentStart}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="segmentEnd-field">End Structure</label>
                <Field
                  name="segmentEnd"
                  type="text"
                  className="form-control"
                  id="segmentEnd-field"
                  value={values.segmentEnd}
                />
                <p className="hint">
                  {errors.segmentEnd && touched.segmentEnd && errors.segmentEnd}
                </p>
              </div>
            </div>
          </div>

          {/*}
                <FieldArray name="segments">
                    {({insert, remove, push}) => (
                        <div>
                            {values.segments.length > 0 &&
                                values.segments.map((segment, index) => (
                                    <div className="order-segment-container" key={index}>
                                        <h3>Structures Segment #{index + 1}</h3>

                                        <div className="segment-range-cover" key={index}>
                                            <div className="form-group">
                                                <label htmlFor={`segments.${index}.start`}>Start</label>
                                                <Field
                                                    name={`segments.${index}.start`}
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor={`segments.${index}.end`}>End</label>
                                                <Field
                                                    name={`segments.${index}.end`}
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-icon"
                                                    onClick={() => remove(index)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                                         stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-dashed mt-4 mb-2"
                                    onClick={() => push({start: '', end: ''})}
                                >
                                    Add new Segment
                                </button>
                            </div>
                        </div>
                    )}
                </FieldArray>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="contactEmail-field">Contact E-mail</label>
                            <Field name="contactEmail" className="form-control" id="contactEmail-field" value={values.contactEmail} />
                            <p className="hint">{errors.contactEmail && touched.contactEmail && errors.contactEmail}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="contactName-field">Contact Name</label>
                            <Field name="contactName" className="form-control" id="contactName-field" value={values.contactName} />
                            <p className="hint">{errors.contactName && touched.contactName && errors.contactName}</p>
                        </div>
                    </div>
                </div>*/}

          <div className="form-group">
            <label htmlFor="comments-field">Comments</label>
            <Field
              name="comments"
              as="textarea"
              rows={5}
              className="form-control"
              id="comments-field"
              value={values.comments}
            ></Field>
            <p className="hint">
              {errors.comments && touched.comments && errors.comments}
            </p>
          </div>

          <div className="form-group">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-warning"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default OrderForm;

import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import picturesvg from "../../assets/img/picture.svg";
import { severityOptions } from "../../helpers/Constants";
import { Tooltip } from "@mui/material";

const Cards = ({
  object,
  defect,
  severity,
  description,
  imageId,
  setSelectedItemId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFullDefect, setShowFullDefect] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDefectDisplay = () => {
    setShowFullDefect(!showFullDefect);
  };

  const displayDefect = (defect) => {
    const maxItemsToShow = 3; // Adjust based on your preference
    if (defect.length <= maxItemsToShow || showFullDefect) {
      return defect.join(", ");
    }
    return `${defect.slice(0, maxItemsToShow).join(", ")}, ...`;
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    toggleDefectDisplay();
  };

  const handleSvgClick = (e) => {
    e.stopPropagation();
    setSelectedItemId(imageId);
    setTimeout(() => setTooltipOpen(false), 200);
  };

  const showToggleLink = defect.length > 3;
  const option = severityOptions.find((option) => option.id === severity);

  return (
    <div
      className={`annotation-card ${isExpanded ? "expanded" : ""}`}
      onClick={toggleExpand}
    >
      <h3 className="annotationCardTitle">{object}</h3>
      <div
        className="severity-dot"
        style={{ backgroundColor: option.color }}
      ></div>

      {isExpanded && ( // Only show the description and URL when expanded
        <>
          <div className="annotationFeatureRow">
            <span className="featureLabel">{`Defect${
              defect.length > 1 ? "s" : ""
            }:`}</span>
            <span className={`featureValue ${showFullDefect ? "showAll" : ""}`}>
              {displayDefect(defect)}
            </span>
          </div>
          {showToggleLink && (
            <button className="toggleDefectLink" onClick={handleToggle}>
              {showFullDefect ? "Hide" : "Show"}
            </button>
          )}
          <div className="annotationFeatureRow">
            <span className="featureLabel">Severity:</span>
            <span className="featureValue" style={{ color: option.color }}>
              {option.severity}
            </span>
          </div>
          {description && (
            <>
              {" "}
              <div className="annotationFeatureRow">
                <span className="featureLabel">Description:</span>
                <span className="featureValue">{description}</span>
              </div>
            </>
          )}
          <Tooltip
            title="Go to Image"
            placement="bottom"
            arrow
            open={tooltipOpen}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          >
            {" "}
            <ReactSVG
              src={picturesvg}
              className="card-image-icon"
              style={{ width: "30px", height: "30px" }}
              onClick={handleSvgClick}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default Cards;

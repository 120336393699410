import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NoMatchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldRender, setShouldRender] = useState(false);
  const isRedirectingRef = useRef(false); // Track if redirection is in progress

  useEffect(() => {
    let presentUrl = location.pathname;

    if (presentUrl.includes("tracking") && !isRedirectingRef.current) {
      let parts = presentUrl.split("tracking");
      let fixedPart = parts[1].replace(/\/{2,}/g, "/");
      let newUrl = `${parts[0]}tracking${fixedPart}`;

      if (newUrl !== presentUrl) {
        isRedirectingRef.current = true; // Mark as redirecting to prevent loops
        navigate(newUrl, { replace: true });
      } else {
        setShouldRender(true); // Render if no redirection needed
      }
    } else {
      setShouldRender(true); // Render if no redirection needed
    }
  }, [location, navigate]);

  if (!shouldRender) {
    return null; // Prevent rendering if redirection is occurring
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="no-page-container">
      <div className="no-page-box">
        <p className="no-page-text">404</p>
        <p className="no-page-sub-text">
          Page you are trying to access could not be found
        </p>
        <button
          type="button"
          className="btn btn-warning"
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NoMatchPage;

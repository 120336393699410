import React, { Component } from "react";
import { Outlet } from "react-router-dom";

import Bar from "../BarPanel/Bar";
import Popup from "../Universal/Popup";

import GridLoader from "react-spinners/GridLoader";

class MainContentLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupPosition: this.props.popupPosition,
      popupVisible: this.props.popupVisible,
      mapVisible: this.props.mapVisible,
      isMobile: window.innerWidth <= 1199,
      mobileMapVisible: false,
      mobileNavVisible: false,
      objects: [],
    };

    this.onPopupToggle = this.onPopupToggle.bind(this);
    this.onMobileNavToggle = this.onMobileNavToggle.bind(this);
  }

  componentDidMount() {}

  onPopupToggle(e, optionalPosition) {
    this.props.onPopupToggle(e, optionalPosition);
  }

  onMobileNavToggle(mobileNavVisible) {
    this.setState({
      mobileNavVisible: mobileNavVisible,
    });
  }

  render() {
    return (
      <div
        className={
          this.state.mobileNavVisible || this.state.mobileMapVisible
            ? "cover menu-open"
            : "cover"
        }
      >
        <div
          className={
            "sweet-loading" + (this.props.loaderVisible ? " open" : "")
          }
        >
          <GridLoader
            color={"rgba(39, 197, 155, 1)"}
            loading={true}
            cssOverride={{
              display: "block",
              margin: "0 auto",
              borderColor: "red",
            }}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>

        <Popup
          position={this.state.popupPosition}
          active={/*this.state.popupVisible*/ false}
        />
        <div
          className={
            this.state.popupVisible
              ? /*'popup-cover active'*/ "popup-cover"
              : "popup-cover"
          }
          onClick={this.onPopupCoverClick}
        ></div>

        <Bar
          onPopupToggle={this.onPopupToggle}
          onMobileNavToggle={this.onMobileNavToggle}
        />

        <Outlet />
      </div>
    );
  }
}

export default MainContentLayout;

import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            height: "22px",
            borderRadius: "20px",
            "& .MuiLinearProgress-bar": {
              borderRadius: "20px",
              backgroundColor: "#0a4273",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            color: "#0a4273",
          }}
          variant="body2"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const ProgressBar = ({ progress }) => {
  return (
    <Box sx={{ width: "650px" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

export default ProgressBar;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCBdYSHOFqJs8jSaXPpr9rriudfBgG6N3c",
  authDomain: "detect-381413.firebaseapp.com",
  projectId: "detect-381413",
  storageBucket: "detect-381413.appspot.com",
  messagingSenderId: "793847104063",
  appId: "1:793847104063:web:18790e048db8025e0f2e8a",
  measurementId: "G-D9H515468E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const messaging = getMessaging(app);

export { db };

import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loader = () => {
  return (
    <div className="loadingOverlay">
      <div className="loadingText">
        <ScaleLoader loading={true} size={12} />
      </div>
    </div>
  );
};

export default Loader;

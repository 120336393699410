import React, { Component } from 'react';

import BarProfile from "./BarProfile";
import BarNav from "./BarNav";

class Bar extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.onPopupToggle = this.onPopupToggle.bind(this);
        this.onMobileNavToggle = this.onMobileNavToggle.bind(this);
    }


    onPopupToggle(e, optionalPosition) {
        this.props.onPopupToggle(e, optionalPosition);
    }


    onMobileNavToggle(mobileNavVisible) {
        this.props.onMobileNavToggle(mobileNavVisible);
    }


    render() {
        return (
            <div id="bar-panel">
                <BarProfile
                    onPopupToggle={this.onPopupToggle}
                />

                <BarNav
                    onMobileNavToggle={this.onMobileNavToggle}
                    onNavClick={this.props.onNavClick}
                />

            </div>
        );
    }
}

export default Bar;

import { toast } from "react-toastify";

export const showNotificationToast = (message) => {
  toast(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    // closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    icon: false,
    progressClassName: "fancy-progress-bar",
  });
};

import {useControl} from 'react-map-gl';

function MapTypeControl(props) {
    useControl(
        (map) => {
            return {
                onAdd: function (map) {
                    this._map = map;
                    this._container = document.createElement('div');
                    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-maptypes';
                    this._container.innerHTML = '<button class="mapboxgl-ctrl-2d active" type="button" data-type="2d" aria-label="Switch to 2D map" aria-disabled="false"><span class="mapboxgl-ctrl-2d-icon" aria-hidden="true" title="Switch to 2D map"></span></button><button class="mapboxgl-ctrl-3d" data-type="3d" type="button" aria-label="Switch to 3D map" aria-disabled="false"><span class="mapboxgl-ctrl-3d-icon" aria-hidden="true" title="Switch to 3D map"></span></button>';

                    this._container.addEventListener('click', (e) => {
                        e.preventDefault();

                        for (const button of document.querySelectorAll('.mapboxgl-ctrl-maptypes button')) {
                            button.classList.remove("active");
                        }
                        e.target.classList.add("active");

                        props.onMapTypeChanged(e.target.getAttribute('data-type'));
                    });

                    return this._container;
                },
                onRemove: function () {
                    this._container.parentNode.removeChild(this._container);
                    this._map = undefined;
                }
            };
        },
        (map) => {
            //console.log(123);
        },
        (map) => {
            //console.log(321);
        },
        {
            position: props.position
        }
    );

    return null;
}

export default MapTypeControl;
import React, { Component } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import ImagesListRow from "./ImagesListRow";
import { useNavigate, useParams } from "react-router-dom";
import { Fancybox } from "@fancyapps/ui";

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const withParams = (Component) => (props) => {
  const params = useParams();
  return <Component {...props} params={params} />;
};

class ImagesListPage extends Component {
  constructor(props) {
    super(props);

    this.checkAuth();

    this.state = {
      user: this.props.user,
      parent: props.params.id,
      breadcrumbs: {},
      items: [],
      files: [],
      activeFile: null,
    };

    this.onNavClick = this.onNavClick.bind(this);
    this.syncItems = this.syncItems.bind(this);
    this.goToDir = this.goToDir.bind(this);
    this.showImage = this.showImage.bind(this);
    this.getNextFile = this.getNextFile.bind(this);
    this.getPrevFile = this.getPrevFile.bind(this);
    this.onNextFileClick = this.onNextFileClick.bind(this);
  }

  onNavClick(e, id, type) {
    e.preventDefault();

    window.appComponent.setState({
      loaderVisible: true,
    });

    if (type === "dir") {
      this.goToDir(id);
    } else {
      this.showImage(id);
    }
  }

  goToDir(id) {
    if (id === null || typeof id === "undefined") {
      id = "";
    }

    window.history.pushState(null, "", "/images/list/" + id);

    this.setState(
      {
        parent: id,
      },
      () => this.syncItems()
    );
  }

  showImage(id) {
    let that = this;

    window.apiHelper.getImageList(id).then((response) => {
      if (response && response.url) {
        this.setState({ activeFile: id });

        let buttons =
          '<div class="fancybox__nav"><button tabindex="0" title="Next" class="f-button is-next" data-carousel-next="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M9 3l9 9-9 9"></path></svg></button><button tabindex="0" title="Previous" class="f-button is-prev" data-carousel-prev="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M15 3l-9 9 9 9"></path></svg></button></div>';

        Fancybox.show(
          [
            {
              src: response.url,
              dragToClose: false,
              touch: false,
              clickSlide: false,
            },
          ],
          {
            on: {
              "*": function (fancyboxCur, eventName) {
                if (eventName === "done") {
                  document
                    .querySelector(".fancybox__viewport")
                    .insertAdjacentHTML("beforeend", buttons);
                  document
                    .querySelector(".fancybox__container .is-next")
                    .addEventListener("click", (e) => {
                      that.onNextFileClick(e, fancyboxCur);
                    });
                  document
                    .querySelector(".fancybox__container .is-prev")
                    .addEventListener("click", (e) => {
                      that.onPrevFileClick(e, fancyboxCur);
                    });
                }
              },
            },
          }
        );
      }

      window.appComponent.setState({
        loaderVisible: false,
      });
    });
  }

  onNextFileClick(e, fancyboxCur) {
    e.preventDefault();
    window.appComponent.setState({ loaderVisible: true });

    let nextFile = this.getNextFile(this.state.activeFile);
    if (nextFile) {
      window.apiHelper.getImageList(nextFile.id).then((response) => {
        this.setState({ activeFile: nextFile.id });
        document
          .querySelector(".fancybox__container .fancybox__content img")
          .setAttribute("src", response.url);

        document
          .querySelector(".fancybox__container .fancybox__content img")
          .addEventListener("load", function () {
            window.appComponent.setState({ loaderVisible: false });
          });
      });
    }
  }

  onPrevFileClick(e, fancyboxCur) {
    e.preventDefault();
    window.appComponent.setState({ loaderVisible: true });

    let prevFile = this.getPrevFile(this.state.activeFile);
    if (prevFile) {
      window.apiHelper.getImageList(prevFile.id).then((response) => {
        this.setState({ activeFile: prevFile.id });
        document
          .querySelector(".fancybox__container .fancybox__content img")
          .setAttribute("src", response.url);

        document
          .querySelector(".fancybox__container .fancybox__content img")
          .addEventListener("load", function () {
            window.appComponent.setState({ loaderVisible: false });
          });
      });
    }
  }

  getNextFile(id) {
    let nextFile = null;
    this.state.files.forEach((item, index) => {
      if (item.id === id && index < this.state.files.length - 1) {
        nextFile = this.state.files[index + 1];
      } else if (item.id === id && index === this.state.files.length - 1) {
        nextFile = this.state.files[0];
      }
    });

    return nextFile;
  }

  getPrevFile(id) {
    let prevFile = null;
    this.state.files.forEach((item, index) => {
      if (item.id === id && index > 0) {
        prevFile = this.state.files[index - 1];
      } else if (item.id === id && index === 0) {
        prevFile = this.state.files[this.state.files.length - 1];
      }
    });

    return prevFile;
  }

  syncItems() {
    window.apiHelper.getImageList(this.state.parent).then((response) => {
      if (response && response.data) {
        this.setState(
          {
            files: this.filterFiles(response.data),
            items: this.sortData(response.data),
            parent: response.parent,
            breadcrumbs: response.breadcrumbs,
          },
          () => {
            window.appComponent.setState({
              loaderVisible: false,
            });
          }
        );
      } else {
        this.goToDir(null);
      }
    });
  }

  sortData(rawData) {
    rawData.sort((a, b) => {
      // Sort by type field
      if (a.type === "dir" && b.type === "file") return -1;
      if (a.type === "file" && b.type === "dir") return 1;

      // If type is the same, sort by label field
      //if (a.label < b.label) return -1;
      //if (a.label > b.label) return 1;

      return 0;
    });

    return rawData;
  }

  filterFiles(rawData) {
    let files = [];
    rawData.forEach((item) => {
      if (item.type === "file") {
        files.push(item);
      }
    });

    /*files.sort((a, b) => {
            // If type is the same, sort by label field
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;

            return 0;
        });*/

    return files;
  }

  checkAuth() {
    /*if (!this.props.user || (this.props.user.role.indexOf('Administrator') === -1 || this.props.user.role.indexOf('Request Inspection') === -1)) {
            window.location = '/';
        }*/
  }

  componentDidMount() {
    document.title = "Images — " + process.env.REACT_APP_PAGE_TITLE;

    this.syncItems();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    let scrollHeight = window.innerHeight;
    if (window.innerWidth <= 1199) {
      scrollHeight *= 10;
    }

    const isMobile = window.innerWidth <= 1199;

    return (
      <>
        <div id="full-width-content">
          <SimpleBar
            style={{
              maxHeight: scrollHeight,
              overflowX: "hidden",
              paddingRight: 13,
              marginRight: -13,
            }}
            autoHide={true}
          >
            <div id="full-width-content-inner">
              <h1 className={"text-center"}>Images List</h1>

              <div className={"container-centered"}>
                {this.state.breadcrumbs && (
                  <ul className={"container-breadcrumbs"}>
                    <li>
                      <a
                        href="#"
                        onClick={(e) => this.onNavClick(e, null, "dir")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                          />
                        </svg>
                      </a>
                    </li>

                    {Object.values(this.state.breadcrumbs)
                      .reverse()
                      .map((breadcrumb) => {
                        return (
                          <li key={breadcrumb.id.toString()}>
                            <a
                              href={"#"}
                              onClick={(e) =>
                                this.onNavClick(e, breadcrumb.id, "dir")
                              }
                            >
                              {breadcrumb.label}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                )}

                <div className={"images-list-container"}>
                  {this.state.breadcrumbs.length > 0 && (
                    <ImagesListRow
                      key={".."}
                      data={this.state.breadcrumbs[1]}
                      isRoot={true}
                      onRowClick={this.onNavClick}
                    />
                  )}
                  {this.state.items &&
                    this.state.items.map((item) => {
                      return (
                        <ImagesListRow
                          key={item.id.toString()}
                          data={item}
                          onRowClick={this.onNavClick}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
      </>
    );
  }
}

export default withParams(withNavigate(ImagesListPage));

import React, { Component } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import OrderForm from "./OrderForm";

class OrderCreatePage extends Component {
    constructor(props) {
        super(props);

        this.checkAuth();

        this.state = {
            user: this.props.user,
            formSubmitted: false,
            utilityProviders: []
        };

        this.onSuccess = this.onSuccess.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }


    checkAuth() {
        /*if (!this.props.user || (this.props.user.role.indexOf('Administrator') === -1 || this.props.user.role.indexOf('Request Inspection') === -1)) {
            window.location = '/';
        }*/
    }


    componentDidMount() {
        document.title = 'Request an Inspection — ' + process.env.REACT_APP_PAGE_TITLE;

        window.apiHelper.orderGetUtilityProviders().then((response) => {
            window.apiHelper.orderGetLineNumbers().then((lnResponse) => {
                this.setState({
                    utilityProviders: response,
                    lineNumbers: lnResponse
                });

                window.appComponent.setState({
                    loaderVisible: false
                });
            });
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    onSuccess(e) {
        this.setState({formSubmitted: true});
    }


    resetForm() {
        window.location.reload();
    }


    render() {
        let scrollHeight = window.innerHeight;
        if (window.innerWidth <= 1199) {scrollHeight *= 10;}

        const isMobile = (window.innerWidth <= 1199);

        return (
            <>
                <div id="full-width-content">
                    <SimpleBar
                            style={{ maxHeight: scrollHeight, overflowX: 'hidden', paddingRight: 13, marginRight: -13 }}
                            autoHide={true}
                        >
                            <div id="full-width-content-inner">
                                <h1 className={'text-center'}>New Order</h1>

                                {!this.state.formSubmitted && this.state.utilityProviders &&
                                <OrderForm
                                    utilityProviders={this.state.utilityProviders}
                                    lineNumbers={this.state.lineNumbers}
                                    onSuccess={this.onSuccess}
                                />
                                }

                                {this.state.formSubmitted &&
                                <div className="py-5 text-center">
                                    Your order has been submitted. We will contact you shortly.

                                    <div className="py-4 text-center">
                                        <button type="button" className="btn btn-dashed mx-4" onClick={this.resetForm}>Submit another application</button>
                                        <button type="button" className="btn btn-dashed mx-4" onClick={(e) => {e.preventDefault(); window.location = '/';}}>Back to Home</button>
                                    </div>
                                </div>
                                }
                            </div>

                        </SimpleBar>
                </div>
            </>
        );
    }
}

export default OrderCreatePage;

import React, { Component } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import ReportCard from "./ReportCard";
import InspectionsListMap from "../inspections/InspectionsListMap";

class ReportsListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            data: []
        };

        window.appComponent.setState({
            loaderVisible: true
        });

        document.title = 'Reports — ' + process.env.REACT_APP_PAGE_TITLE;

        this.onPopupToggle = this.onPopupToggle.bind(this);
        this.onMapTogglerClick = this.onMapTogglerClick.bind(this);
        this.updateData = this.updateData.bind(this);
    }


    componentDidMount() {
        window.apiHelper.getWorkordersList().then((workordersList) => {
            this.updateData(workordersList);
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user !== this.props.user) {
            window.apiHelper.getWorkordersList().then((workordersList) => {
                this.updateData(workordersList);
            });
        }
    }


    updateData(data) {
        this.setState({
            data: data
        }, () => {
            window.appComponent.setState({
                loaderVisible: false
            });
        });
    }


    onMapTogglerClick(e) {
        this.setState({
            mobileMapVisible: !this.state.mobileMapVisible
        });
    }
    onPopupToggle(e, optionalPosition) {
        this.props.onPopupToggle(e, optionalPosition);
    }


    render() {
        let scrollHeight = window.innerHeight - 131;
        if (window.innerWidth <= 1199) {scrollHeight *= 10;}

        const isMobile = (window.innerWidth <= 1199);

        return (
            <>
                <div id="main-panel">
                    <div className="panel-top">
                        <div className="back">
                        </div>

                        <h1>Reports</h1>

                        <div className="btn-group">
                            {isMobile &&
                            <button onClick={this.onMapTogglerClick}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M5.53845 5.53846C5.53845 4.17899 6.64054 3.07692 7.99999 3.07692C9.35944 3.07692 10.4615 4.17899 10.4615 5.53846C10.4615 6.8979 9.35944 8 7.99999 8C6.64054 8 5.53845 6.8979 5.53845 5.53846ZM7.99999 4.30769C7.32024 4.30769 6.76922 4.8587 6.76922 5.53846C6.76922 6.21821 7.32024 6.76923 7.99999 6.76923C8.67974 6.76923 9.23076 6.21821 9.23076 5.53846C9.23076 4.8587 8.67974 4.30769 7.99999 4.30769Z"
                                          fill="#333333"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M2.46155 5.53846C2.46155 2.47966 4.94118 0 8.00001 0C11.0588 0 13.5385 2.47966 13.5385 5.53846C13.5385 6.14855 13.2513 6.94972 12.8841 7.73249C12.5031 8.54455 11.9869 9.4368 11.4351 10.2644C10.8843 11.0906 10.2859 11.8708 9.73521 12.4521C9.46099 12.7415 9.18481 12.9964 8.92075 13.1836C8.6797 13.3544 8.35416 13.5385 8.00001 13.5385C7.64586 13.5385 7.32032 13.3544 7.07927 13.1836C6.81521 12.9964 6.53902 12.7415 6.26481 12.4521C5.7141 11.8708 5.1157 11.0906 4.56487 10.2644C4.01313 9.4368 3.49691 8.54455 3.11597 7.73249C2.74872 6.94972 2.46155 6.14855 2.46155 5.53846ZM8.00001 1.23077C5.62093 1.23077 3.69232 3.15939 3.69232 5.53846C3.69232 5.85145 3.86668 6.43489 4.23021 7.20978C4.58001 7.95545 5.06382 8.79397 5.58899 9.58172C6.11509 10.3709 6.67053 11.0907 7.15829 11.6056C7.40327 11.8642 7.61749 12.0565 7.79096 12.1794C7.90444 12.2599 7.97207 12.2902 8.00001 12.3014C8.02795 12.2902 8.09558 12.2599 8.20906 12.1794C8.38253 12.0565 8.59675 11.8642 8.84173 11.6056C9.32949 11.0907 9.88493 10.3709 10.411 9.58172C10.9362 8.79397 11.42 7.95545 11.7698 7.20978C12.1334 6.43489 12.3077 5.85145 12.3077 5.53846C12.3077 3.15939 10.3791 1.23077 8.00001 1.23077Z"
                                          fill="#333333"/>
                                    <path
                                        d="M3.25286 11.678C3.57854 11.5809 3.76379 11.2381 3.66662 10.9124C3.56946 10.5867 3.22667 10.4015 2.90099 10.4986C2.11025 10.7345 1.42165 11.0359 0.916892 11.4042C0.424517 11.7634 0 12.27 0 12.9231C0 13.5323 0.370978 14.0148 0.8148 14.3646C1.26556 14.7198 1.87967 15.0128 2.58369 15.2474C3.99815 15.719 5.91305 16 8 16C10.087 16 12.0018 15.719 13.4163 15.2474C14.1203 15.0128 14.7345 14.7198 15.1852 14.3646C15.629 14.0148 16 13.5323 16 12.9231C16 12.27 15.5755 11.7634 15.0831 11.4042C14.5783 11.0359 13.8897 10.7345 13.099 10.4986C12.7734 10.4015 12.4305 10.5867 12.3334 10.9124C12.2362 11.2381 12.4215 11.5809 12.7471 11.678C13.4672 11.8929 14.0094 12.1443 14.3577 12.3985C14.7183 12.6617 14.7692 12.8471 14.7692 12.9231C14.7692 12.9936 14.727 13.1586 14.4234 13.3979C14.1268 13.6316 13.6594 13.869 13.0271 14.0798C11.7689 14.4993 9.99145 14.7692 8 14.7692C6.00855 14.7692 4.23114 14.4993 2.97289 14.0798C2.34055 13.869 1.87322 13.6316 1.57661 13.3979C1.27306 13.1586 1.23077 12.9936 1.23077 12.9231C1.23077 12.8471 1.28164 12.6617 1.64234 12.3985C1.99066 12.1443 2.53282 11.8929 3.25286 11.678Z"
                                        fill="#333333"/>
                                </svg>
                            </button>
                            }
                        </div>
                    </div>

                    <div className="inspections-list">

                        <div className="form-group search-group d-none">
                            <input type="text" className="form-control" placeholder="Search..." />
                            <button>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.0058 14C5.13982 14 2.0058 10.866 2.0058 6.99997C2.0058 3.13396 5.13979 0 9.0058 0C12.8718 0 16.0058 3.13399 16.0058 7C16.0058 10.866 12.8718 14 9.0058 14ZM9.0058 2.00001C6.24437 2.00001 4.00581 4.23857 4.00581 7C4.00581 9.76143 6.24437 12 9.0058 12C11.7672 12 14.0058 9.76143 14.0058 7C14.0058 4.23857 11.7672 2.00001 9.0058 2.00001Z" fill="#CACACA"/>
                                    <path d="M1.0058 16C0.739987 16.0015 0.484514 15.8972 0.295804 15.71C-0.0963325 15.321 -0.0989176 14.6879 0.290014 14.2958C0.291944 14.2938 0.293874 14.2919 0.295804 14.29L4.05579 10.53C4.46172 10.1379 5.10867 10.1491 5.50081 10.555C5.89295 10.9609 5.88174 11.6079 5.47582 12L1.7158 15.71C1.52709 15.8972 1.27161 16.0015 1.0058 16Z" fill="#CACACA"/>
                                </svg>
                            </button>
                        </div>

                        <SimpleBar
                            style={{ maxHeight: scrollHeight, overflowX: 'hidden', paddingRight: 13, marginRight: -13 }}
                            autoHide={true}
                        >
                            <h2 className="d-none">
                                All Reports

                                <button>
                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 10H0V12H5V10Z" fill="#FF8039"/>
                                        <path d="M11 5H0V7H11V5Z" fill="#FF8039"/>
                                        <path d="M16 0H0V2H16V0Z" fill="#FF8039"/>
                                    </svg>
                                </button>
                            </h2>

                            <div className="inspection-cards-cover">
                                {this.state.data && this.state.data.length && this.state.data.map((item) =>
                                    <ReportCard
                                        key={item.id.toString()}
                                        id={item.id.toString()}
                                        data={item}
                                        onPopupToggle={this.onPopupToggle}
                                    />
                                )}
                            </div>
                        </SimpleBar>

                    </div>
                </div>

                <InspectionsListMap
                    mobileMapVisible={this.state.mobileMapVisible}

                    onObjectSelected={this.onObjectSelected}
                />
            </>
        );
    }
}

export default ReportsListPage;

export class GradientPolylineLayer {
    constructor() {
    }

    static create(inspection) {
        let gradient = ['interpolate', ['linear'], ['line-progress']];

        const lowColor = 'rgba(102, 187, 106)',
            mediumColor = 'rgba(249, 168, 37)',
            highColor = 'rgba(221, 44, 0)';

        let progress = 0;
        inspection.structure_data.forEach((structureData) => {
            let highestPriority = 0, curColor = lowColor;
            //TODO: add priority calculation
            /*if (object.annotations) {
                for (const annotation of object.annotations) {
                    let priorityValue = 0;
                    switch (annotation.priority) {
                        case 'low': priorityValue = 1; break;
                        case 'medium': priorityValue = 2; break;
                        case 'high': priorityValue = 3; break;
                    }

                    if (highestPriority < priorityValue) {
                        highestPriority = priorityValue;
                    }
                }
            }*/


            if (structureData[2] === 2) {
                curColor = mediumColor;
            } else if (structureData[2] > 2) {
                curColor = highColor;
            }

            gradient.push(progress, curColor);

            progress += 1 / inspection.structure_data.length;
        });

        let layer = {
            beforeId: 'heatmap',
            type: 'line',
            maxzoom: 24,
            interactive: true,
            paint: {
                'line-color': 'transparent',
                'line-width': 0,

                //'line-gradient': ['interpolate', ['linear'], ['line-progress'], 0, 'transparent', 1, 'transparent']
            },
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            }
        };

        return layer;
    }
}
import * as React from 'react';
import AuthContext from "./AuthContext";


/**
 * AuthProvider - The Authentication Context Provider
 *
 * @param children
 * @param authStorageName
 * @param cookieDomain
 * @param cookieSecure
 *
 * @return Functional Component
 */
const AuthProvider =
    ({
         children,
         user
     }) => {

        return (
            <AuthContext.Provider value={user}>
                {children}
            </AuthContext.Provider>
        );
    };

export default AuthProvider;
import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DropdownMenu = ({
  placeholder,
  options,
  defaultValue,
  selectedOption,
}) => {
  const [personName, setPersonName] = React.useState(defaultValue || "");
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const filteredData = options.filter((item) => item.name === value);

    setPersonName(value);
    selectedOption(filteredData);
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300, mt: 3, height: 300 }}>
        <Select
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected) {
              return <em>{placeholder}</em>;
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((name) => (
            <MenuItem key={name.id} value={name.name}>
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownMenu;

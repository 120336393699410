import React, { Component } from 'react';
import {Layer, Source} from "react-map-gl";
import {GradientPolylineLayer} from "../../helpers/GradientPolylineLayer.js";
import {GradientPolylineLayerPhantom} from "../../helpers/GradientPolylineLayerPhantom";

class GradientPolylineSource extends Component {
    constructor(props) {
        super(props);
    }


    createGeoJSONData() {
        let data = {
            "type": "FeatureCollection",
            "features": []
        };

        let features = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "coordinates": [],
                "type": "LineString"
            }
        };


        if (this.props.inspection.structure_data) {
            this.props.inspection.structure_data.forEach((structureData) => {
                features.geometry.coordinates.push([structureData[1], structureData[0]]);
            });
        }

        data.features.push(features);

        return data;
    }


    render() {
        const layer = GradientPolylineLayer.create(this.props.inspection);
        const layerPhantom = GradientPolylineLayerPhantom.create(this.props.inspection);

        return (
            <>
                <Source id={'grad-poly-phantom-'+this.props.inspection.request_id} type="geojson" lineMetrics={true} data={this.createGeoJSONData()}>
                    <Layer {...layerPhantom} />
                </Source>
                <Source id={'grad-poly-'+this.props.inspection.request_id} type="geojson" lineMetrics={true} data={this.createGeoJSONData()}>
                    <Layer {...layer} />
                </Source>
            </>
        );
    }
}

export default GradientPolylineSource;

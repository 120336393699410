import React, { useState } from "react";
import { mapStyles } from "./Constants";

const MapStyleSelector = ({ setSelectedLayer, selectedLayer }) => {
  const [hoveredStyle, setHoveredStyle] = useState(null);

  const handleStyleSelect = (style) => {
    setSelectedLayer(style);
  };

  const handleMouseEnter = (style) => {
    setHoveredStyle(style);
  };

  const handleMouseLeave = () => {
    setHoveredStyle(null);
  };

  return (
    <div className="style-selector-container">
      {Object.keys(mapStyles).map((style) => (
        <div
          key={style}
          className="style-box"
          onClick={() => handleStyleSelect(style)}
          onMouseEnter={() => handleMouseEnter(style)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={mapStyles[style].image}
            alt={`${style} map style`}
            style={{
              border:
                (selectedLayer === style || hoveredStyle === style) &&
                "2px solid #007bff",
            }}
          />
          <div
            className="style-label"
            style={{
              color:
                (selectedLayer === style || hoveredStyle === style) &&
                "#007bff",
            }}
          >
            {style}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MapStyleSelector;

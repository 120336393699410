import React, { Component } from 'react';
import {Link, Navigate} from "react-router-dom";

class AuthPageResetConfirmForm extends Component {
    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            password: '',
            password2: '',
            token: urlParams.get('token'),
            formError: null,
            formSubmitted: false
        };

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPassword2Change = this.onPassword2Change.bind(this);
        this.onResetConfirm = this.onResetConfirm.bind(this);
    }


    onPasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }


    onPassword2Change(e) {
        this.setState({
            password2: e.target.value
        });
    }


    async onResetConfirm(e) {
        e.preventDefault();
        if (this.state.password !== this.state.password2) {
            this.setState({
                formError: 'Passwords do not match'
            });
            return;
        }

        console.log('Trying to confirm password...');


        let result = await window.appComponent.authHelper.resetConfirm(this.state.password, this.state.token);

        console.log('result', result);

        if (result) {
            window.location = '/auth';
        } else {
            this.setState({
                formError: true
            })
        }
    }


    render() {
        return (
            <>
                {this.state.formSubmitted && (
                    <Navigate to="/auth" replace={true}/>
                )}

                <form action="/">
                    <p className="subheader"><Link to='/auth'>« back to login</Link></p>

                    <h1>Set your new password</h1>

                    <div className="form-group">
                        <label htmlFor="password-field">Password</label>
                        <input type="password" name="password-field" defaultValue={this.state.password} className="form-control" id="password-field" onChange={this.onPasswordChange}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password-field-2">Re-password</label>
                        <input type="password" name="password-field-2" defaultValue={this.state.password2} className="form-control" id="password-field-2" onChange={this.onPassword2Change}/>
                        {this.state.formError &&
                            <p className="hint">{this.state.formError}</p>
                        }
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-warning" onClick={this.onResetConfirm}>Save new password</button>
                    </div>
                </form>
            </>
        );
    }
}

export default AuthPageResetConfirmForm;

import React, { Component } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

const withNavigate = Component => props => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

class ReportStructuresStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.onCardClick = this.onCardClick.bind(this);
    }


    onCardClick(e) {
        e.preventDefault();

        this.props.navigate('/reports/'+this.props.data.id);
    }


    getChart(high, medium) {
        ChartJS.register(ArcElement, Tooltip, Legend);
        let chartData = {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
                {
                    label: '# of Issues',
                    data: [high, medium],
                    backgroundColor: [
                        'rgba(221, 44, 0, 0.8)',
                        'rgba(249, 168, 37, 0.8)',
                    ],
                    borderColor: [
                        'rgba(221, 44, 0, 1)',
                        'rgba(249, 168, 37, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        if ((high + medium) === 0) {
            chartData.datasets[0].data = [1];
            chartData.datasets[0].backgroundColor = ['rgba(230, 230, 230, 0.8)'];
            chartData.datasets[0].borderColor = ['rgba(230, 230, 230, 1)'];
        }

        return chartData;
    }


    render() {
        const chartOptions = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            }
        };

        return (
            <>
                <div className="report-structures-stats">
                    <div className="big-numbers">
                        <div>
                            <h5>Inspected Structures</h5>
                            <span>{this.props.stats.total_number_of_structures}</span>
                        </div>
                        <div>
                            <h5>Issues Detected</h5>
                            <span>{this.props.stats.total_number_of_defects}</span>
                        </div>
                    </div>
                    <div className="charts-block">
                        <h5>High / Medium of all</h5>
                        <div className="charts-block-content">
                            <div className="charts-block-chart">
                                <Doughnut data={this.getChart(this.props.stats.high, this.props.stats.medium)} options={chartOptions} />
                            </div>

                            <div className="charts-block-data">
                                <div className="line-chart-cover high">
                                    <span>{Math.round(this.props.stats.high / this.props.stats.total_number_of_defects * 100)}% High issue(s)</span>
                                    <div className="line-chart"><div style={{width: Math.round(this.props.stats.high / this.props.stats.total_number_of_defects * 100)+'%'}}></div></div>
                                </div>
                                <div className="line-chart-cover medium">
                                    <span>{Math.round(this.props.stats.medium / this.props.stats.total_number_of_defects * 100)}% Medium issue(s)</span>
                                    <div className="line-chart"><div style={{width: Math.round(this.props.stats.medium / this.props.stats.total_number_of_defects * 100)+'%'}}></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-structures-details">
                    <div className="big-numbers">
                        <h5>Structures with issues</h5>
                        <div className="big-numbers-content">
                            <div className="high">
                                <span>High</span>
                                <span>{this.props.stats.high}</span>
                            </div>
                            <div className="medium">
                                <span>Medium</span>
                                <span>{this.props.stats.medium}</span>
                            </div>
                        </div>
                    </div>
                    <div className="structures-list">
                        <h5>Most Common Issues</h5>
                        <div className="structures-list-content">
                            {this.props.topAnnotations && Object.entries(this.props.topAnnotations).map(([key, value]) =>
                                <div className="structures-list-item" key={key}>
                                    <span>{Math.round(value / this.props.stats.total_number_of_defects * 100)}%</span><div>{key}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withNavigate(ReportStructuresStats);

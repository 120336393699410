import React, { Component } from 'react';

import $ from "jquery";

class Popup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: {
                top: ((this.props.top)?(this.props.top):(0)),
                left: ((this.props.left)?(this.props.left):(0))
            },
            active: ((this.props.active)?(this.props.active):(false))
        };

        this.curContainer = React.createRef()

        this.onMapInitialized = this.onMapInitialized.bind(this);
    }


    onMapInitialized(map) {
    }


    componentDidMount() {
    }


    componentDidUpdate() {
        let fixedPosition = {};
        const padding = 20,
              coverWidth = document.querySelector('.cover').offsetWidth,
              currentWidth = this.curContainer.current.offsetWidth,
              coverHeight = document.querySelector('.cover').offsetHeight,
              currentHeight = this.curContainer.current.offsetHeight;

        if (
            currentWidth &&
            coverWidth < this.state.position.left + currentWidth + padding
        ) {
            const fixedLeft = coverWidth - currentWidth - padding;
            this.curContainer.current.style.marginLeft = fixedLeft+'px';
        }
        if (
            currentHeight &&
            coverHeight < this.state.position.top + currentHeight + padding
        ) {
            const fixedTop = coverHeight - currentHeight - padding;
            this.curContainer.current.style.marginTop = fixedTop+'px';
        }
    }


    toggleVisibility() {
        /*if (!this.state.active) {
            let elems = document.querySelectorAll('.popup.active');

            [].forEach.call(elems, function(el) {
                el.classList.remove('active');
            });
        }*/

        this.setState({
            active: !this.state.active
        });
    }


    componentWillReceiveProps(props) {
        this.setState(() => {
            let newProps = {};

            return props;
        });
    }


    render() {
        return (
            <div
                ref={this.curContainer}
                className={(this.state.active)?('popup active'):('popup')}
                style={{
                    marginTop: this.state.position.top+'px',
                    marginLeft: this.state.position.left+'px'
                }}
            >
                <ul>
                    <li>
                        <a href="/">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.1004 1.13931C11.5813 -0.379769 9.11837 -0.379769 7.59931 1.13931L5.65758 3.08099C5.65758 3.08099 5.65299 3.08373 5.64684 3.08606C5.63377 3.09099 5.61136 3.09631 5.58079 3.09646C3.78952 3.10566 2.22278 4.13721 1.43833 4.74963C0.690544 5.33342 0.731418 6.3738 1.32808 6.97042L4.13634 9.77873L0.17048 14.736C-0.077155 15.0456 -0.0524389 15.4918 0.227871 15.7721C0.508173 16.0524 0.954423 16.0772 1.26397 15.8295L6.22129 11.8637L9.0296 14.6719C9.62622 15.2686 10.6665 15.3095 11.2504 14.5617C11.8628 13.7773 12.8943 12.2105 12.9036 10.4192C12.9037 10.3887 12.909 10.3663 12.9139 10.3532C12.9163 10.347 12.9191 10.3424 12.9191 10.3424L14.8607 8.40071C16.3798 6.88165 16.3798 4.41876 14.8607 2.89965L13.1004 1.13931ZM6.83581 10.2777L10.0841 13.526C10.6409 12.7949 11.3414 11.6189 11.3476 10.4112C11.3497 10.0163 11.483 9.57801 11.8177 9.24325L13.7605 7.30051C14.672 6.38904 14.672 4.91129 13.7605 3.99983L12.0001 2.23952C11.0887 1.32807 9.61098 1.32807 8.69951 2.23952L6.75677 4.18226C6.42201 4.51702 5.9837 4.65036 5.5888 4.65239C4.38116 4.65861 3.20511 5.35909 2.4739 5.91588L5.7223 9.16421C5.72728 9.16903 5.7321 9.17394 5.73693 9.17884L6.8211 10.2631C6.82608 10.2679 6.83098 10.2727 6.83581 10.2777V10.2777Z" fill="#333333"/>
                            </svg>

                            Stick on Top
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.0791 0.180251C13.9637 0.0648408 13.8071 0 13.6439 0C13.4807 0 13.3241 0.0648408 13.2087 0.180251L6.6812 6.70779C6.61362 6.77536 6.56273 6.8577 6.53251 6.94835L5.66218 9.55934C5.58845 9.78046 5.64606 10.0243 5.81087 10.1891C5.97568 10.354 6.21951 10.4115 6.44063 10.3378L9.05168 9.46746C9.14227 9.43724 9.22467 9.38634 9.29218 9.31877L15.8197 2.79126C16.0601 2.55093 16.0601 2.16126 15.8197 1.92093L14.0791 0.180251ZM7.65424 7.4754L13.6439 1.48576L14.5142 2.3561L8.52463 8.34573L7.21908 8.78089L7.65424 7.4754Z" fill="#333333"/>
                                <path d="M0.615421 1.23083C0.275536 1.23083 0 1.50637 0 1.84626V15.3855C0 15.7254 0.275536 16.0009 0.615421 16.0009H14.1547C14.4946 16.0009 14.7701 15.7254 14.7701 15.3855V6.76963C14.7701 6.42973 14.4946 6.15421 14.1547 6.15421C13.8148 6.15421 13.5393 6.42973 13.5393 6.76963V14.7701H1.23084V2.46168H9.23132C9.57122 2.46168 9.84674 2.18614 9.84674 1.84626C9.84674 1.50637 9.57122 1.23083 9.23132 1.23083H0.615421Z" fill="#333333"/>
                            </svg>

                            Edit
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.8468 1.09164C15.0965 0.84191 15.0965 0.437025 14.8468 0.187294C14.5971 -0.0624312 14.1921 -0.0624312 13.9424 0.187294L11.5038 2.62592C11.4903 2.61918 11.4766 2.61286 11.4624 2.60699C10.4637 2.19115 9.30815 1.9184 7.99992 1.9184C4.06537 1.9184 1.55392 4.37377 0.45863 5.74069C-0.15375 6.50499 -0.152279 7.56535 0.459461 8.32862C1.03097 9.04169 1.97499 10.0364 3.29711 10.8326L1.15309 12.9767C0.903354 13.2264 0.903354 13.6313 1.15309 13.881C1.40281 14.1307 1.8077 14.1307 2.05743 13.881L14.8468 1.09164ZM4.23333 9.8964L5.79632 8.33342C5.57142 7.95261 5.44205 7.50805 5.44205 7.03414C5.44205 5.6215 6.58728 4.47627 7.99992 4.47627C8.47383 4.47627 8.91839 4.60557 9.29919 4.83054L10.5153 3.61446C9.76895 3.3574 8.93124 3.19734 7.99992 3.19734C4.63773 3.19734 2.45436 5.2953 1.4567 6.54041C1.21958 6.83636 1.21955 7.23193 1.45743 7.52877C2.00906 8.21703 2.93669 9.18109 4.23333 9.8964ZM8.3313 5.79844C8.22572 5.77024 8.11464 5.75521 7.99992 5.75521C7.29357 5.75521 6.72099 6.32779 6.72099 7.03414C6.72099 7.14887 6.73602 7.25994 6.76422 7.36552L8.3313 5.79844Z" fill="#333333"/>
                                <path d="M12.8697 4.07941C13.0889 3.80246 13.491 3.75565 13.7679 3.97486C14.5447 4.58958 15.135 5.23372 15.5407 5.74005C16.1526 6.50351 16.1535 7.56349 15.5412 8.32759C14.4459 9.69451 11.9345 12.1499 7.99992 12.1499C7.26408 12.1499 6.57563 12.0635 5.93552 11.9135C5.59168 11.8329 5.37829 11.4888 5.45893 11.145C5.53957 10.8011 5.88366 10.5877 6.2275 10.6683C6.77451 10.7966 7.36499 10.8709 7.99992 10.8709C11.3621 10.8709 13.5455 8.77298 14.5431 7.52787C14.7804 7.23173 14.7804 6.83648 14.5427 6.53983C14.1827 6.0906 13.6593 5.51981 12.9743 4.97767C12.6973 4.75853 12.6505 4.35637 12.8697 4.07941Z" fill="#333333"/>
                                <path d="M10.0223 7.07071C10.3553 7.18837 10.5298 7.5537 10.4121 7.88667C10.1551 8.61394 9.57972 9.18927 8.85252 9.44627C8.51948 9.564 8.15416 9.38949 8.03649 9.05645C7.91877 8.72348 8.09334 8.35815 8.42631 8.24049C8.78921 8.11221 9.07799 7.82343 9.20627 7.46053C9.32393 7.1275 9.68926 6.95299 10.0223 7.07071Z" fill="#333333"/>
                            </svg>

                            Hide
                        </a>
                    </li>
                    <li className="delete-item">
                        <a href="/">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.09091 0.727273C5.09091 0.325615 5.41651 0 5.81818 0H10.1818C10.5835 0 10.9091 0.325615 10.9091 0.727273V2.18182H15.2727C15.6744 2.18182 16 2.50743 16 2.90909C16 3.31075 15.6744 3.63636 15.2727 3.63636H0.727273C0.325615 3.63636 0 3.31075 0 2.90909C0 2.50743 0.325615 2.18182 0.727273 2.18182H5.09091V0.727273ZM6.54545 2.18182H9.45455V1.45455H6.54545V2.18182Z" fill="#DD2C00"/>
                                <path d="M6.54545 8C6.94712 8 7.27272 8.3256 7.27272 8.72727V14.5455C7.27272 14.9471 6.94712 15.2727 6.54545 15.2727C6.14378 15.2727 5.81818 14.9471 5.81818 14.5455V8.72727C5.81818 8.3256 6.14378 8 6.54545 8Z" fill="#DD2C00"/>
                                <path d="M10.1818 8.72727C10.1818 8.3256 9.85623 8 9.45455 8C9.05288 8 8.72728 8.3256 8.72728 8.72727V14.5455C8.72728 14.9471 9.05288 15.2727 9.45455 15.2727C9.85623 15.2727 10.1818 14.9471 10.1818 14.5455V8.72727Z" fill="#DD2C00"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.6324 5.34168C1.77054 5.1824 1.97099 5.09091 2.18182 5.09091H13.8182C14.029 5.09091 14.2295 5.1824 14.3676 5.34168C14.5058 5.50095 14.5679 5.71229 14.5382 5.92102L13.0542 16.3086C12.9007 17.3834 11.9801 18.1818 10.8943 18.1818H5.10568C4.01993 18.1818 3.09934 17.3834 2.94579 16.3086L1.46186 5.92102C1.43204 5.71229 1.49427 5.50095 1.6324 5.34168ZM3.02038 6.54546L4.38575 16.1028C4.43688 16.4612 4.74379 16.7273 5.10568 16.7273H10.8943C11.2562 16.7273 11.5631 16.4612 11.6143 16.1028L12.9796 6.54546H3.02038Z" fill="#DD2C00"/>
                            </svg>

                            Delete
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Popup;

import React from "react";
import mark from "../assets/img/icons/mark.png";
import "simplebar-react/dist/simplebar.min.css";
import { getTimeDifference, truncateString } from "./Utils";
import InfiniteScroll from "./InfiniteScroll";

const NotificationsList = ({
  notifications,
  fetchNotifications,
  redirectLink,
  fetchScrollNotifications,
  loading,
  hasMore,
  setBulkRead,
}) => {
  const handleBulkReadBtn = async () => {
    try {
      const response = await window.apiHelper.bulkReadNotifications();
      if (response) {
        setBulkRead(true);
        fetchNotifications();
      }
    } catch (error) {
      console.log("Error in bulk read notionfication:", error);
    }
  };

  return (
    <div className="notifications-list">
      <InfiniteScroll
        maxHeight={"560px"}
        fetchWorkordersList={fetchScrollNotifications}
        loading={loading}
        hasMore={hasMore}
      >
        <div className="notification-read-container">
          <button className="notification-read-btn" onClick={handleBulkReadBtn}>
            <img src={mark} alt="read icon" className="mark-icon"></img>
            mark all as read
          </button>
        </div>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className="notification-row"
              onClick={() => redirectLink(notification)}
            >
              <div>
                <p className="notification-title">
                  {notification.unread_count > 0
                    ? `${notification.unread_count} new ${
                        notification.unread_count > 1 ? "messages" : "message"
                      } `
                    : `${notification.total_count} ${
                        notification.total_count > 1 ? "messages" : "message"
                      } `}
                  {` on inspection: ${notification.request_id} / structure: ${notification.structure}`}
                </p>
                <p className="notification-message">
                  {`last message: ${truncateString(notification.msg, 25)}`}
                </p>
                <p className="notification-time">
                  {getTimeDifference(notification.latest_message)}
                </p>
              </div>
              {notification.unread_count > 0 && <div className="read-status" />}
            </div>
          ))
        ) : (
          <div style={{ color: "black", padding: "10px" }}>
            No notifications yet
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default NotificationsList;

import React, { Component } from "react";
import MapContainer from "../../Components/Map/MapContainer";

class InspectionsListMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };

    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    window.apiHelper.getWorkordersHeatmap().then((workordersList) => {
      this.updateData(workordersList);
    });
    /*window.apiHelper.getQuickAccessInspections().then((quickAccessData) => {
            this.setState({
                quickAccessData: quickAccessData
            });
        });*/
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== this.props.user) {
      window.apiHelper.getWorkordersHeatmap().then((workordersList) => {
        this.updateData(workordersList);
      });
      /*window.apiHelper.getQuickAccessInspections().then((quickAccessData) => {
                this.setState({
                    quickAccessData: quickAccessData
                });
            });*/
    }
  }

  updateData(data) {
    this.setState({
      data: data,
    });
  }

  render() {
    return (
      <MapContainer
        inspections={this.state.data}
        inspectionsGradient={true}
        mobileMapVisible={this.props.mobileMapVisible}
      />
    );
  }
}

export default InspectionsListMap;

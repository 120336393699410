import * as React from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import { StatusConstants } from "../../helpers/Constants";

const StatusSelectionPopup = ({
  setStatusPopup,
  value,
  rowId,
  handleStatusChange,
  setTableLoad,
}) => {
  const status = StatusConstants[value];
  const handleOptionSelect = async (option) => {
    setTimeout(() => {
      setTableLoad(true);
    }, 100);

    setStatusPopup(false);
    const data = {
      status: option,
    };
    try {
      const response = await window.apiHelper.getUpdateStatus(rowId, data);

      if (response) {
        handleStatusChange(true);
      } else {
        handleStatusChange(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <MenuList style={{ padding: "0px" }}>
      {status !== "PENDING" && (
        <MenuItem onClick={() => handleOptionSelect(1)}>
          <Chip
            label="pending"
            sx={{
              backgroundColor: "rgba(204, 204, 204, 0.8)",
              color: "#000000",
              fontWeight: 500,
              fontSize: "13.5px",
            }}
          />
        </MenuItem>
      )}
      {status !== "PHOTOS UPLOADED" && (
        <MenuItem onClick={() => handleOptionSelect(2)}>
          <Chip
            label="photos uploaded"
            sx={{
              backgroundColor: "rgba(159, 197, 248, 0.5)",
              color: "#0a4273",
              fontWeight: 500,
              fontSize: "13.5px",
            }}
          />
        </MenuItem>
      )}
      {status !== "ANNOTATED" && (
        <MenuItem onClick={() => handleOptionSelect(3)}>
          <Chip
            label="annotated"
            sx={{
              backgroundColor: "#fee2d3",
              color: "#ff8039",
              fontWeight: 500,
              fontSize: "13.5px",
            }}
          />
        </MenuItem>
      )}
      {status !== "REVIEWED" && (
        <MenuItem onClick={() => handleOptionSelect(4)}>
          <Chip
            label="reviewed"
            sx={{
              backgroundColor: "rgba(188, 119, 66, 0.45)",
              color: "#633900",
              fontWeight: 500,
              fontSize: "13.5px",
            }}
          />
        </MenuItem>
      )}
      {status !== "APPROVED" && (
        <MenuItem onClick={() => handleOptionSelect(6)}>
          <Chip
            label="approved"
            sx={{
              backgroundColor: "#ddffcd",
              color: "#007f00",
              fontWeight: 500,
              fontSize: "13.5px",
            }}
          />
        </MenuItem>
      )}

      {status !== "REJECTED" && (
        <MenuItem onClick={() => handleOptionSelect(5)}>
          <Chip
            label="rejected"
            sx={{
              backgroundColor: "#ffd7d7",
              color: "#dd2c00",
              fontWeight: 500,
              fontSize: "13.5px",
            }}
          />
        </MenuItem>
      )}
    </MenuList>
  );
};

export default StatusSelectionPopup;

import React, { useRef, useEffect } from "react";
import DropdownMenu from "./DropdownMenu";
import ClosePopup from "../assets/img/icons/cancel.png";

const Popup = ({
  text,
  onAccept,
  onReject,
  type,
  placeholder,
  options,
  defaultValue,
  selectedOption,
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onReject();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onReject]);

  return (
    <div className="overlay-message">
      <div className="centered-box" ref={popupRef}>
        <button onClick={onReject} className="close-popup-button">
          <img src={ClosePopup} width="30" height="30" alt="arrow-left" />
        </button>
        <p className="centered-text">{text}</p>
        {type === "dropdown" && (
          <div>
            <DropdownMenu
              placeholder={placeholder}
              options={options}
              defaultValue={defaultValue}
              selectedOption={selectedOption}
            />
          </div>
        )}
        <div
          className={`${type === "option" ? "button-box" : "button-box-v2"}`}
        >
          {type === "option" && (
            <>
              <button className="approve-button" onClick={onAccept}>
                APPROVE
              </button>
              <button className="reject-button" onClick={onReject}>
                REJECT
              </button>
            </>
          )}
          {type === "info" && (
            <button className="approve-button" onClick={onAccept}>
              OKAY
            </button>
          )}

          {type === "dropdown" && (
            <button className="approve-button" onClick={onAccept}>
              APPROVE
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;

import React, { Component } from "react";

class GuestSession extends Component {
  constructor(props) {
    super(props);

    const token = this.findGetParameter("token");
    if (!token || !token.length) {
      window.location.href = "/auth";
      return;
    }

    console.log("Token: " + token);

    /*const guestLoginPromise = fetch(process.env.REACT_APP_API_URL + '/secured-rest/guest-login', {
            method: 'POST',
            headers: {
                //'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token
            })
        }).then(data => data.json()).then(res => {
            if (!res.data) {
                console.log('Error: no data in response');
                //window.location.href = '/auth';
                return;
            }

            if (this.props.signIn({
                token: res.data.token,
                expiresIn: res.data.expiresIn,
                tokenType: "Bearer",
                authState: res.data.authUserState
            })) {
                window.location = '/';
            } else {
                console.log('Something went wrong');
                //Throw error
            }
        });*/
  }

  findGetParameter(parameterName) {
    let result = null,
      tmp = [];
    let items = window.location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
  }

  render() {
    return <div></div>;
  }
}

export default GuestSession;

import type { HeatmapLayer } from "react-map-gl";

const MAX_ZOOM_LEVEL = 20;

export const heatmapLayer: HeatmapLayer = {
  id: "heatmap",
  maxzoom: MAX_ZOOM_LEVEL,
  type: "heatmap",
  paint: {
    // Increase the heatmap weight based on frequency and property value
    "heatmap-weight": [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0,
      0,
      300,
      1,
    ],
    // Increase the heatmap color weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 2],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.7,
      "rgb(103,169,207)",
      0.95,
      "rgb(255,212,142)",
      1.05,
      "rgb(221,44,0)",
    ],
    // Adjust the heatmap radius by zoom level
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 4, 18, 50],
    // Transition from heatmap to circle layer by zoom level
    "heatmap-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],
      7,
      1,
      MAX_ZOOM_LEVEL,
      1,
    ],
  },
};

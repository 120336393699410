import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import AuthContext from "./AuthContext";

const RequireAuth = ({ children, path, permission }) => {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error("RequireAuth must be used within an AuthProvider");
  }

  const location = useLocation();

  if (
    context.isGuest ||
    (permission && !context.permissions.includes(permission))
  ) {
    return <Navigate to={path} state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;

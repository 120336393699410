import React, {Component} from 'react';

import iconUnhide from "../assets/img/unhide.svg";
import iconHide from "../assets/img/hide.svg";

class AnnotatedImage {

    scales = [1, 2, 4];
    currentScale = 1;

    stats = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
    };

    constructor(photoData, priority, caption) {
        this.createContainer();

        console.log(photoData, priority, caption);

        this.stats = photoData.stats;
        this.priority = priority;

        this.createImageOriginal(photoData);
        this.createImageDraggable(photoData);

        this.createDefectInfo(photoData);

        this.createAnnotationsToggler();

        this.createScaleToggler();

        this.createCaptionContainer(caption);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }


    createContainer() {
        this.containerHTML = document.createElement('div');
        this.containerHTML.classList.add('image-defects');
    }


    createDefectInfo(photoData) {
        this.defectInfo = document.createElement('div');
        this.defectInfo.classList.add('image-defect-info', this.priority);

        const styles = {
            'left': (this.stats.x / this.stats.imageWidth * 100)+'%',
            'top': (this.stats.y / this.stats.imageHeight * 100)+'%',
            'width': (photoData.stats.w / this.stats.imageWidth * 100)+'%',
            'height': (photoData.stats.h / this.stats.imageHeight * 100)+'%'
        };
        for (let key in styles) {
            this.defectInfo.style[key] = styles[key];
        }

        //this.containerHTML.appendChild(this.defectInfo);
        this.imageDraggable.appendChild(this.defectInfo);
    }
    updateDefectInfo(scaleFactor) {
        const newStyles = {
            'left': (this.stats.x / this.stats.imageWidth * 100 * scaleFactor) - (scaleFactor-1)*50+'%',
            'top': (this.stats.y / this.stats.imageHeight * 100 * scaleFactor) - (scaleFactor-1)*50+'%',
            'width': (this.stats.w / this.stats.imageWidth * 100 * scaleFactor)+'%',
            'height': (this.stats.h / this.stats.imageHeight * 100 * scaleFactor)+'%'
        };

        for (let key in newStyles) {
            //this.defectInfo.style[key] = newStyles[key];
        }
    }


    createImageOriginal(photoData) {
        this.imageOriginal = document.createElement('img');
        this.imageOriginal.classList.add('image-original');

        const attributes = {
            'src': photoData.content,
            'width': photoData.stats.imageWidth,
            'height': photoData.stats.imageHeight
        };
        for (let key in attributes) {
            this.imageOriginal.setAttribute(key, attributes[key]);
        }

        this.containerHTML.appendChild(this.imageOriginal);
    }


    createImageDraggable(photoData) {
        this.imageDraggable = document.createElement('div');
        this.imageDraggable.classList.add('image-draggable');
        this.imageDraggableImg = document.createElement('img');
        this.imageDraggable.appendChild(this.imageDraggableImg);

        const attributes = {
            'src': photoData.content,
            'width': photoData.stats.imageWidth,
            'height': photoData.stats.imageHeight
        };
        for (let key in attributes) {
            this.imageDraggableImg.setAttribute(key, attributes[key]);
        }

        this.imageDraggable.addEventListener('mousedown', this.onDragStart.bind(this));
        this.imageDraggable.addEventListener('touchstart', this.onDragStart.bind(this));

        this.containerHTML.appendChild(this.imageDraggable);
    }
    onDragStart(e) {
        e.preventDefault();
        e.stopPropagation();
        //console.log('onDragStart');

        if (e.type === 'touchstart') {
            e.clientX = e.touches[0].clientX;
            e.clientY = e.touches[0].clientY;
        }

        if (!this.initialDragPosition) {
            this.centerImage();
        }

        this.initialClick = {
            x: e.clientX,
            y: e.clientY,
            lastX: e.clientX,
            lastY: e.clientY
        };

        if (!this.initialDefectInfoPosition) {
            this.initialDefectInfoPosition = {
                pxX: 0,
                pxY: 0,
                percentX: parseFloat(this.defectInfo.style.left),
                percentY: parseFloat(this.defectInfo.style.top)
            }
        }

        this.imageDraggable.addEventListener('mousemove', this.onDrag);
        this.imageDraggable.addEventListener('mouseup', this.onDragEnd);
        this.imageDraggable.addEventListener('mouseout', this.onDragEnd);

        this.imageDraggable.addEventListener('touchmove', this.onDrag);
        this.imageDraggable.addEventListener('touchend', this.onDragEnd);
        this.imageDraggable.addEventListener('touchcancel', this.onDragEnd);
    }
    onDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        //console.log('onDrag');

        if (e.type === 'touchmove') {
            e.clientX = e.touches[0].clientX;
            e.clientY = e.touches[0].clientY;
        }

        this.initialClick.lastX = e.clientX;
        this.initialClick.lastY = e.clientY;

        let deltaX = e.clientX - this.initialClick.x,
            deltaY = e.clientY - this.initialClick.y,
            newPositionX = this.initialDragPosition.x + deltaX,
            newPositionY = this.initialDragPosition.y + deltaY;

        if (newPositionX > 0) {
            this.initialClick.x += newPositionX;
            newPositionX = 0;
        } else if (newPositionX < (-1)*(this.currentScale - 1) * this.containerHTML.offsetWidth) {
            this.initialClick.x += newPositionX - (-1)*(this.currentScale - 1) * this.containerHTML.offsetWidth;
            newPositionX = (-1)*(this.currentScale - 1) * this.containerHTML.offsetWidth;
        }
        if (newPositionY > 0) {
            this.initialClick.y += newPositionY;
            newPositionY = 0;
        } else if (newPositionY < (-1)*(this.currentScale - 1) * this.containerHTML.offsetHeight) {
            this.initialClick.y += newPositionY - (-1)*(this.currentScale - 1) * this.containerHTML.offsetHeight;
            newPositionY = (-1)*(this.currentScale - 1) * this.containerHTML.offsetHeight;
        }

        this.imageDraggable.style.left = newPositionX + 'px';
        this.imageDraggable.style.top = newPositionY + 'px';

        //this.defectInfo.style.left = (this.initialDefectInfoPosition.percentX + (deltaX + this.initialDefectInfoPosition.pxX) / this.containerHTML.offsetWidth * 100) + '%';
        //this.defectInfo.style.top = (this.initialDefectInfoPosition.percentY + (deltaY + this.initialDefectInfoPosition.pxY) / this.containerHTML.offsetHeight * 100) + '%';
    }
    onDragEnd(e) {
        e.preventDefault();
        e.stopPropagation();
        //console.log('onDragEnd');

        this.initialDragPosition = {
            x: parseInt(this.imageDraggable.style.left),
            y: parseInt(this.imageDraggable.style.top)
        };

        this.initialDefectInfoPosition['pxX'] = this.initialClick.lastX - this.initialClick.x;
        this.initialDefectInfoPosition['pxY'] = this.initialClick.lastY - this.initialClick.y;

        this.imageDraggable.removeEventListener('mousemove', this.onDrag);
        this.imageDraggable.removeEventListener('mouseup', this.onDragEnd);
        this.imageDraggable.removeEventListener('mouseout', this.onDragEnd);

        this.imageDraggable.removeEventListener('touchmove', this.onDrag);
        this.imageDraggable.removeEventListener('touchend', this.onDragEnd);
        this.imageDraggable.removeEventListener('touchcancel', this.onDragEnd);
    }


    createAnnotationsToggler() {
        this.annotationsToggler = document.createElement('button');
        this.annotationsToggler.classList.add('annotations-toggler', 'active');
        this.annotationsToggler.innerHTML = '<img src="'+iconUnhide+'" alt="Show annotations" class="icon-unhide" />'+'<img src="'+iconHide+'" alt="Hide annotations" class="icon-hide" />';
        this.annotationsToggler.addEventListener('click', this.onAnnotationsTogglerClick.bind(this));

        this.containerHTML.appendChild(this.annotationsToggler);
    }
    onAnnotationsTogglerClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.annotationsToggler.classList.contains('active')) {
            this.annotationsToggler.classList.remove('active');
            this.defectInfo.classList.add('passive');
        } else {
            this.annotationsToggler.classList.add('active');
            this.defectInfo.classList.remove('passive');
        }
    }


    createScaleToggler() {
        if (!this.scales) {return;}

        this.scalesContainer = document.createElement('div');
        this.scalesContainer.classList.add('scales-container');

        let scaleButtons = {};
        for (let scale of this.scales) {
            scaleButtons[scale] = document.createElement('button');
            scaleButtons[scale].innerHTML = scale * 100 + '%';
            scaleButtons[scale].setAttribute('data-scale', scale);
            scaleButtons[scale].addEventListener('click', this.onScaleTogglerClick.bind(this));
            this.scalesContainer.appendChild(scaleButtons[scale]);
        }
        scaleButtons[this.scales[0]].classList.add('active');
        this.currentScale = this.scales[0];

        this.containerHTML.appendChild(this.scalesContainer);
    }
    onScaleTogglerClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const scaleFactor = parseInt(e.target.getAttribute('data-scale'));

        if (!e.target.classList.contains('active')) {
            this.updateDefectInfo(scaleFactor);

            document.querySelectorAll('.scales-container button').forEach((button) => {
                button.classList.remove('active');
            });
            e.target.classList.add('active');

            for (let scale of this.scales) {
                if (scale !== scaleFactor) {
                    this.containerHTML.classList.remove('scale-'+scale);
                }
            }
            this.containerHTML.classList.add('scale-'+scaleFactor);

            this.currentScale = scaleFactor;

            this.centerImage();
        }
    }
    centerImage() {
        this.initialDragPosition = {
            x: (-1) * (this.currentScale - 1) * this.containerHTML.offsetWidth / 2,
            y: (-1) * (this.currentScale - 1) * this.containerHTML.offsetHeight / 2
        };

        this.imageDraggable.style.left = this.initialDragPosition.x + 'px';
        this.imageDraggable.style.top = this.initialDragPosition.y + 'px';


        const styles = {
            'left': ((-1) * (this.currentScale - 1) * 50 + this.currentScale * parseFloat(this.stats.x))+'%',
            'top': ((-1) * (this.currentScale - 1) * 50 + this.currentScale * parseFloat(this.stats.y))+'%',
        };
        for (let key in styles) {
            //this.defectInfo.style[key] = styles[key];
        }
        this.initialDefectInfoPosition = null;
    }


    createCaptionContainer(caption) {
        this.captionContainer = document.createElement('div');
        this.captionContainer.classList.add('caption-container');
        this.captionContainer.innerHTML = caption;
    }


    render() {
        let content = document.createElement('div');
        content.appendChild(this.containerHTML);
        content.appendChild(this.captionContainer);

        return content;
    }
}

export default AnnotatedImage;
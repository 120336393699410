import React, { Component } from 'react';

class AuthPageLogout extends Component {
    constructor(props) {
        super(props);

        window.appComponent.authHelper.logout();
    }
}

export default AuthPageLogout;

import React from "react";

class AuthHelper {
  constructor() {
    this.blankUser = {
      id: null,
      email: null,
      token: null,

      role: "guest", // or 'customer', 'admin'
      permissions: [],
      isGuest: true,
    };
  }

  initUserData() {
    let user = this.blankUser;

    const token = localStorage.getItem("access_token");
    const userId = localStorage.getItem("user_id");
    const userEmail = localStorage.getItem("user_email");
    let userPermissions = localStorage.getItem("user_permissions");
    if (userPermissions) {
      userPermissions = userPermissions.split(",");
    } else {
      userPermissions = [];
    }

    if (token && userId && userEmail) {
      user = {
        id: userId,
        email: userEmail,
        token: token,

        role: "customer",
        permissions: userPermissions,
        isGuest: false,
      };
    }

    return user;
  }

  /**
   * Try to authorize user
   * @param {string} email
   * @param {string} password
   * @returns {boolean}
   */
  async login(email, password) {
    let result = false;

    const response = await window.apiHelper.postRequest(
      window.apiHelper.apiUrls.auth.login,
      {
        email: email,
        password: password,
      }
    );

    /**
     * Auth permissions:
     * inspection = inspections page
     * request = request page
     * upload = upload images page
     * images = images page
     */

    if (response && response.token) {
      localStorage.setItem("access_token", response.token);
      localStorage.setItem("user_id", response.id);
      localStorage.setItem("user_email", response.email);
      localStorage.setItem("user_org", response.org);
      localStorage.setItem("user_permissions", response.permissions.join(","));

      result = true;
    }

    return result;
  }

  /**
   * Try to reset customer's email
   * @param {string} email
   * @returns {boolean}
   */
  async reset(email) {
    let result = false;

    const response = await window.apiHelper.postRequest(
      window.apiHelper.apiUrls.auth.reset,
      {
        email: email,
      }
    );

    if (response && response.status === "OK") {
      result = true;
    }

    return result;
  }

  /**
   * Try to confirm new password for the customer
   * @param {string} password
   * @param {string} token
   * @returns {boolean}
   */
  async resetConfirm(password, token) {
    let result = false;

    const response = await window.apiHelper.postRequest(
      window.apiHelper.apiUrls.auth.resetConfirm,
      {
        password: password,
        token: token,
      }
    );

    if (response && response.status === "OK") {
      result = true;
    }

    return result;
  }

  /**
   * Logout user
   * @returns {boolean}
   */
  async logout() {
    let result = false;

    const response = window.apiHelper.postRequest(
      window.apiHelper.apiUrls.auth.logout
    );

    if (response) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_email");
      localStorage.removeItem("user_org");
      localStorage.removeItem("user_permissions");
      localStorage.removeItem("org");
      localStorage.removeItem("requestData");
      window.location = "/auth";

      result = true;
    }
    return result;
  }
}

export default AuthHelper;

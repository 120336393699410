import React, { Component } from 'react';
import { Layer, Source } from 'react-map-gl';
import parse from 'html-react-parser';


class InspectionObject extends Component {
    constructor(props) {
        super(props);

        this.togglePopup = this.togglePopup.bind(this);
    }


    togglePopup(e) {
        if (e.originalEvent) {
            e.originalEvent.stopPropagation();
            e.originalEvent.preventDefault();
        }

        this.props.onMarkerClicked(this.props.object);
    }


    render() {
        let coords = [];
        if (this.props.inspection && this.props.inspection.structure_data && this.props.inspection.structure_data.length) {
            for (const structureData of this.props.inspection.structure_data) {
                coords.push([structureData[1], structureData[0]]);
            }
        }

        const geojson = {
            type: 'FeatureCollection',
            features: [
                {type: 'Feature', geometry: {type: 'LineString', coordinates: coords}}
            ]
        };

        return (
            <Source id="my-data" type="geojson" data={geojson}>
                <Layer
                    id={'inspection-'+this.props.inspection.request_id}
                    type={'line'}
                    paint={{'line-color': 'transparent'}}
                />
            </Source>
        );
    }
}

export default InspectionObject;

import React, { Component } from "react";
import { Fancybox } from "@fancyapps/ui";
import MapContainer from "../../Components/Map/MapContainer";

class InspectionViewMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      structures: props.structures,
    };

    this.filteredStructures = this.filteredStructures.bind(this);
    this.isSearched = this.isSearched.bind(this);
  }

  severityToText(severity) {
    switch (severity) {
      case 1:
        return "good";
      case 2:
        return "low";
      case 3:
        return "medium";
      case 4:
        return "high";
      default:
        return "good";
    }
  }

  filteredStructures() {
    let filteredStructures = [];

    for (let structure of this.props.structures) {
      let filtered = false,
        filteredAnnotations = [];
      for (const annotation of structure.annotations) {
        let priorityCondition =
          !this.props.priority ||
          !this.props.priority.length ||
          this.props.priority.includes(
            this.severityToText(annotation.severity)
          );
        let searchTermCondition = this.isSearched(annotation);

        filtered = filtered || (priorityCondition && searchTermCondition);
        if (priorityCondition && searchTermCondition) {
          filteredAnnotations.push(annotation);
        }
      }
      if (filtered) {
        let filteredObject = JSON.parse(JSON.stringify(structure));
        filteredObject.annotations = filteredAnnotations;
        filteredStructures.push(filteredObject);
      }
    }

    return filteredStructures;
  }

  isSearched(annotation) {
    if (!this.props.searchTerm || !annotation) {
      return true;
    }
    const searchTerm = this.props.searchTerm.toLowerCase();

    let isSearched = false;
    if (
      //annotation.id && annotation.id.toString().toLowerCase().indexOf(searchTerm) >= 0 ||
      (annotation.feature &&
        annotation.feature.toString().toLowerCase().indexOf(searchTerm) >= 0) ||
      (annotation.defect &&
        annotation.defect.join(", ").toLowerCase().indexOf(searchTerm) >= 0) ||
      (annotation.description &&
        annotation.description.toString().toLowerCase().indexOf(searchTerm) >=
          0)
    ) {
      isSearched = true;
    }

    return isSearched;
  }

  componentDidMount() {
    Fancybox.bind("[data-fancybox]");
  }

  render() {
    const filteredStructures = this.filteredStructures();

    let mapCenter = null;
    if (filteredStructures.length) {
      mapCenter = filteredStructures[0].coordinates;
    }

    if (!mapCenter) {
      return <></>;
    } else {
      return (
        <MapContainer
          center={mapCenter}
          structures={filteredStructures}
          activeStructureIdentifier={this.props.activeStructureIdentifier}
          mobileMapVisible={this.props.mobileMapVisible}
          onStructureSelected={this.props.onStructureSelected}
        />
      );
    }
  }
}

export default InspectionViewMap;

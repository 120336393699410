import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import ApiHelper from "./helpers/ApiHelper";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/basics.css";
import "./assets/css/styles.css";
import "./assets/css/styles-xl.css";
import "./assets/css/styles-lg.css";
import "./assets/css/styles-md.css";
import "./assets/css/styles-sm.css";
import "./assets/css/styles-xs.css";
import "./assets/css/dev-styles.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { detectEnvironment } from "./helpers/Utils";

const environment = process.env.REACT_APP_NAME;

if (environment !== "local") {
  Sentry.init({
    dsn: "https://46b009e968d2173dea0d0769933a30b5@o4506119329873920.ingest.sentry.io/4506854281183232",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: environment,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/.*\.detect-inspections\.com\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

window.apiHelper = new ApiHelper(detectEnvironment());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

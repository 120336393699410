import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";

import { ReactSVG } from "react-svg";

import logo from "./../../assets/img/logo.svg";

// images taken from this lib: https://www.flaticon.com/packs/miscellaneous-142

import imgNavHome from "./../../assets/img/home.svg";
import imgNavInspections from "./../../assets/img/map.svg";
import imgNavReports from "./../../assets/img/paper.svg";
import imgNavInspectionOrder from "./../../assets/img/complete.svg";
import imgNavImagesUpload from "./../../assets/img/upload.svg";
import imgNavImagesList from "./../../assets/img/image.svg";
import imgNavLogout from "./../../assets/img/logout.svg";

import menuImg from "../../assets/img/menu.svg";
import AuthContext from "../../pages/auth/AuthContext";

const BarNav = (props) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    props.onMobileNavToggle(mobileNavVisible);
  }, [mobileNavVisible]);

  const onNavClick = (e, target, navigate) => {
    e.stopPropagation();

    setMobileNavVisible(false);

    return navigate(target);
  };

  const onLogoutClick = (e) => {
    e.stopPropagation();

    window.appComponent.authHelper.logout();
  };

  return (
    <nav>
      <Link to="/" className="logo">
        <ReactSVG src={logo} className="svg-wrapper" />
      </Link>

      <button
        className="mobile-nav"
        onClick={() => setMobileNavVisible(!mobileNavVisible)}
      >
        <img src={menuImg} alt="" />
      </button>

      <ul className={mobileNavVisible ? "active" : ""}>
        {/*<li onClick={(e) => {onNavClick(e, '/', navigate)}}>
                    <ReactSVG src={imgNavHome} className="svg-wrapper" />
                    Home
                </li>*/}
        <li
          onClick={(e) => {
            onNavClick(e, "/", navigate);
          }}
          className={
            window.location.pathname.indexOf("/inspections") >= 0 ||
            ["", "/"].includes(window.location.pathname)
              ? "active"
              : ""
          }
        >
          <ReactSVG src={imgNavInspections} className="svg-wrapper" />
          Inspection
        </li>
        {/* {authContext.permissions.includes("request") && (
          <li
            onClick={(e) => {
              onNavClick(e, "/reports", navigate);
            }}
            className={
              window.location.pathname.indexOf("/reports") >= 0 ? "active" : ""
            }
          >
            <ReactSVG src={imgNavReports} className="svg-wrapper" />
            Reports
          </li>
        )} */}
        {authContext.permissions.includes("add_request") && (
          <li
            onClick={(e) => {
              onNavClick(e, "/orders/create", navigate);
            }}
            className={
              window.location.pathname.indexOf("/orders/create") >= 0
                ? "active"
                : ""
            }
          >
            <ReactSVG src={imgNavInspectionOrder} className="svg-wrapper" />
            Request
          </li>
        )}

        <li
          onClick={(e) => {
            onNavClick(e, "/images/list", navigate);
          }}
          className={
            window.location.pathname.indexOf("/images/list") >= 0
              ? "active"
              : ""
          }
        >
          <ReactSVG src={imgNavImagesList} className="svg-wrapper" />
          Images
        </li>

        <li onClick={(e) => onLogoutClick(e)}>
          <ReactSVG src={imgNavLogout} className="svg-wrapper" />
          Logout
        </li>
      </ul>
    </nav>
  );
};

export default BarNav;

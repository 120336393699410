import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import { defaults as defaultInteractions } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import { Style, Stroke, Fill, Text } from "ol/style";
import { Polygon, Point } from "ol/geom";
import { transform, transformExtent } from "ol/proj";
import {
  ColorButton,
  annotationSeverityOptions,
} from "../../helpers/Constants";

const environment =
  process.env.REACT_APP_NAME === "local" ? "stage" : process.env.REACT_APP_NAME;

const DEFAULT_ZOOM_VALUE = 4;

const TileAnnotationWindow = ({
  selectedImageDetails,
  setAnnotationWindowLoad,
}) => {
  const mapRef = useRef(null);
  const [showVectorLayers, setShowVectorLayers] = useState(true);
  const vectorLayers = useRef([]);
  const [mapVal, setMapVal] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const urlParts = window.location.pathname.split("/");
  const inspectionsIndex = urlParts.indexOf("tracking");

  const requestId = urlParts[inspectionsIndex + 1];
  const imageId = urlParts[inspectionsIndex + 4];

  const getRectangleCoordinates = (x, y, width, height) => {
    const topLeft = [x, y];
    const topRight = [x + width, y];
    const bottomRight = [x + width, y + height];
    const bottomLeft = [x, y + height];

    const closingCoord = [x, y];

    return [topLeft, topRight, bottomRight, bottomLeft, closingCoord];
  };

  const convertPixelCoordsToCoordinates = (squareCoords, map, callback) => {
    map.once("rendercomplete", () => {
      const coordinates = squareCoords.map((pixel) => {
        const coordinate = map.getCoordinateFromPixel(pixel);
        return coordinate !== null ? coordinate : undefined;
      });
      callback(coordinates);
    });
  };

  const transformedArray = selectedImageDetails.annotations.map((item) => {
    return {
      h: item.data.h,
      w: item.data.w,
      x: item.data.x,
      y: item.data.y,
      imageWidth: item.data.imageWidth,
      imageHeight: item.data.imageHeight,
      message: `${item.feature}: ${item.defect.join(", ")}`,
      severity: item.severity,
    };
  });
  const center = transform([0, 45], "EPSG:4326", "EPSG:3857");

  const initialExtent = transformExtent(
    [-180, -65, 172.5, 85],
    "EPSG:4326",
    "EPSG:3857"
  );
  const subsequentExtent = transformExtent(
    [-180, -85, 176.5, 85],
    "EPSG:4326",
    "EPSG:3857"
  );

  useEffect(() => {
    const source = new XYZ({
      url: `https://storage.googleapis.com/detect-django/${environment}/tiles/${requestId}/${imageId}/{z}/{x}/{y}.jpg`,
    });
    const map = new Map({
      target: mapRef.current,
      layers: [
        // new TileLayer({
        //   source: new OSM(), // Using OpenStreetMap as default tile source
        // }),
        new TileLayer({
          source: source,
        }),
      ],
      view: new View({
        center: center,
        zoom: 0,
        extent: isInitialLoad ? initialExtent : subsequentExtent,
        maxZoom: selectedImageDetails?.zoom_level
          ? selectedImageDetails?.zoom_level
          : DEFAULT_ZOOM_VALUE,
      }),
      interactions: defaultInteractions({ mouseWheelZoom: false }),
      controls: [],
    });

    // Handle mouse wheel event
    const handleMouseWheel = (event) => {
      const delta = event.deltaY > 0 ? -0.35 : 0.35; // Determine zoom direction
      const view = map.getView();
      const currentZoom = view.getZoom();
      view.setZoom(currentZoom + delta);
    };

    // Add event listener for mouse wheel to the map container
    const mapContainer = map.getTargetElement();
    mapContainer.addEventListener("wheel", handleMouseWheel, {
      passive: false,
    });
    setMapVal(map);
    setIsInitialLoad(false);
    return () => {
      mapContainer.removeEventListener("wheel", handleMouseWheel);
      map.setTarget(null);
    };
  }, [selectedImageDetails, isInitialLoad]);

  useEffect(() => {
    if (!mapVal) return;
    if (transformedArray.length > 0) {
      transformedArray.forEach((item) => {
        const scaleX = 0.058;
        const scaleY = 0.057;

        const x = item.x;
        const y = item.y;
        const width = item.w;
        const height = item.h;

        const rectangleCoords = getRectangleCoordinates(
          x * scaleX,
          y * scaleY,
          width * scaleX,
          height * scaleY
        );

        convertPixelCoordsToCoordinates(
          rectangleCoords,
          mapVal,
          (coordinates) => {
            const squareFeature = new Feature({
              geometry: new Polygon([coordinates]),
            });

            // Calculate the center of the square's bounding box
            const bbox = squareFeature.getGeometry().getExtent();
            const center = [
              (bbox[0] + bbox[2]) / 2, // x-coordinate
              (bbox[1] + bbox[3]) / 2, // y-coordinate
            ];

            let option = annotationSeverityOptions.find(
              (option) => option.id === item.severity
            );
            let color = option.color;
            let fill = option.fill;

            // Style the square feature
            squareFeature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 2,
                }),
                fill: new Fill({
                  color: fill, // Red color with 50% opacity
                }),
              })
            );

            // Create a text label
            const textLabel = new Feature({
              geometry: new Point(center), // Assuming the first coordinate as the label position
            });

            // Style the text label
            textLabel.setStyle(
              new Style({
                text: new Text({
                  font: "12px Arial",
                  text: item.message,
                  fill: new Fill({
                    color: [255, 255, 255, 1],
                  }),
                  backgroundFill: new Fill({
                    color: [0, 0, 0, 0.6],
                  }),
                  padding: [4, 4, 4, 4],

                  offsetY: -10, // Adjust the label position to appear above the square
                }),
              })
            );

            // Create a vector layer with the square feature
            const vectorLayer = new VectorLayer({
              source: new VectorSource({
                features: [squareFeature, textLabel],
              }),
            });

            vectorLayers.current.push(vectorLayer);

            if (showVectorLayers) {
              mapVal.addLayer(vectorLayer);
            }
            setAnnotationWindowLoad(false);
          }
        );
      });
    } else {
      setAnnotationWindowLoad(false);
    }
  }, [mapVal]);

  const toggleVectorLayers = () => {
    setShowVectorLayers((prev) => !prev);

    if (showVectorLayers) {
      vectorLayers.current.forEach((layer) => mapVal.removeLayer(layer));
    } else {
      vectorLayers.current.forEach((layer) => mapVal.addLayer(layer));
    }
  };

  return (
    <>
      <div
        ref={mapRef}
        style={{
          width: "100%",
          height: "100%",
          display: "block",
          boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",
        }}
      ></div>

      {selectedImageDetails?.annotated && (
        <ColorButton
          onClick={toggleVectorLayers}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
        >
          {showVectorLayers ? "Hide" : "Show"} Annotations
        </ColorButton>
      )}
    </>
  );
};

export default TileAnnotationWindow;

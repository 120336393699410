import React, { useContext, useState } from "react";
import Divider from "../../helpers/Divider";
import Cards from "../../Components/StructureSummary/Cards";
import { statusOptions } from "../../helpers/Constants";
import { showNotificationToast } from "../../helpers/Messages";
import AuthContext from "../auth/AuthContext";

const StructureSummary = ({
  setSelectedItemId,
  structureTracker,
  setStructureTracker,
}) => {
  const authContext = useContext(AuthContext);

  const trackerId = structureTracker.id;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateStatus = async (status) => {
    const data = {
      status: status,
    };

    try {
      const response = await window.apiHelper.getUpdateStatus(trackerId, data);

      if (response) {
        showNotificationToast(
          `Status updated to ${
            status === approvedOption.value ? "Approved" : "Rejected"
          }`
        );
        setStructureTracker((currentState) => ({
          ...currentState,
          status: status,
        }));
      }
    } catch (e) {
      console.log(e);
      showNotificationToast("Error, please try again!");
    }
  };

  let option = statusOptions.find(
    (option) => option.value === structureTracker.status
  );
  let approvedOption = statusOptions.find(
    (option) => option.label === "APPROVED"
  );
  let rejectedOption = statusOptions.find(
    (option) => option.label === "REJECTED"
  );

  return (
    <>
      {Object.keys(structureTracker).length !== 0 && (
        <div className="stats-container">
          <div className="summary-stats">
            <div className="summary-card">
              <div className="card-title">Total Images</div>
              <div className="card-value">
                {structureTracker.image_summary.total_count}
              </div>
            </div>
            <Divider size="short" />
            <div className="summary-card">
              <div className="card-title">Date Inspected</div>
              <div className="card-value">
                {formatDate(structureTracker.image_summary.earliest_image)}
              </div>
            </div>
          </div>
          <div className="summary-annotations">
            {structureTracker.annotations.map((item, index) => (
              <div key={index}>
                {
                  <Cards
                    object={item.feature}
                    defect={item.defect}
                    severity={item.severity}
                    description={item.description}
                    imageId={item.image_id}
                    setSelectedItemId={setSelectedItemId}
                  />
                }
              </div>
            ))}
          </div>
          <div className="structure-status">
            <div
              className="current-status"
              style={{
                backgroundColor: option.backgroundColor,
                color: option.color,
              }}
            >
              {`Current status: ${option.label}`}
            </div>
            {authContext.permissions.includes(
              "can_change_structure_status"
            ) && (
              <div className="status-buttons">
                <div
                  className="status-button"
                  style={{
                    backgroundColor: approvedOption.backgroundColor,
                    color: approvedOption.color,
                  }}
                  onClick={() => updateStatus(6)}
                >
                  Approve
                </div>
                <div
                  className="status-button"
                  style={{
                    backgroundColor: rejectedOption.backgroundColor,
                    color: rejectedOption.color,
                  }}
                  onClick={() => updateStatus(5)}
                >
                  Reject
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StructureSummary;

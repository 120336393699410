import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const withNavigate = Component => props => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const withParams = Component => props => {
  const params = useParams();
  return <Component {...props} params={params} />;
};


class ImagesListRow extends Component {
    constructor(props) {
        super(props);

        this.onRowClick = this.onRowClick.bind(this);
    }


    onRowClick(e) {
        e.preventDefault();

        let type = 'dir';
        if (this.props.data && this.props.data.type) {
            type = this.props.data.type;
        }

        let id = '';
        if (this.props.data && this.props.data.id) {
            id = this.props.data.id;
        } else if (this.props.data && !this.props.data.id) {
            id = this.props.data;
        }

        this.props.onRowClick(e, id, type);
    }


    render() {
        let containerClass = 'images-list-row';
        if (this.props.active) {containerClass += ' active';}

        if (!this.props.data || this.props.isRoot) {
            return (
                <div className={containerClass} onClick={this.onRowClick}>
                    ..
                </div>
            );
        }

        return (
            <div className={containerClass} onClick={this.onRowClick}>
                {this.props.data && this.props.data.type === 'dir' && (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="images-list-row-prefix">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"/>
                </svg>
                )}
                {this.props.data && this.props.data.type === 'file' && (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="images-list-row-prefix">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
                </svg>
                )}

                <span>
                    {(this.props.isRoot === true) ? ('..') : (this.props.data.label)}
                </span>
            </div>
        );
    }
}

export default ImagesListRow;

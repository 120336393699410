import React, { useEffect } from "react";
import cross from "../assets/img/cross.jpg";
import { useNavigate } from "react-router-dom";

const NoAccessPage = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };
  useEffect(() => {
    window.appComponent.setState({
      loaderVisible: false,
    });
  }, []);

  return (
    <div className="no-access-container">
      <div className="no-access-box">
        <img src={cross} alt="Cross" className="no-access-image" />
        <p className="no-access-text">
          You don't have permission to view this page
        </p>
        <p className="no-access-subtext">
          Please contact your account manager or{" "}
          <a href="mailto:support@detectinspections.com">
            support@detectinspections.com
          </a>{" "}
          for access.
        </p>
        <button
          type="submit"
          className="btn btn-warning"
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NoAccessPage;

import React, { Component } from "react";
import { Marker, Popup } from "react-map-gl";
import parse from "html-react-parser";
import { detectEnvironment } from "../../helpers/Utils";

class MarkerObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTabActive: !document.hidden, // Initial state based on tab visibility
    };

    this.togglePopup = this.togglePopup.bind(this);
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (this.state.isTabActive !== prevState.isTabActive) {
    //   window.appComponent.setState({
    //     loaderVisible: true,
    //   });
    //   setTimeout(() => {
    //     window.appComponent.setState({
    //       loaderVisible: false,
    //     });
    //   }, 1000);
    // }
  }

  handleVisibilityChange = () => {
    this.setState({ isTabActive: !document.hidden });
  };

  togglePopup(e) {
    if (e.originalEvent) {
      e.originalEvent.stopPropagation();
      e.originalEvent.preventDefault();
    }

    this.props.onMarkerClicked(this.props.structure);
  }

  getPriority() {
    if (
      !this.props.structure ||
      !this.props.structure.annotations ||
      !this.props.structure.annotations.length
    ) {
      return;
    } //Check if this structure has annotations

    let curPriority = "";
    for (const annotation of this.props.structure.annotations) {
      if (
        curPriority === "" ||
        (curPriority === "low" &&
          (annotation.severity === 3 || annotation.severity === 4)) ||
        (curPriority === "medium" && annotation.severity === 4)
      ) {
        switch (annotation.severity) {
          case 1:
            curPriority = "good";
            break;
          case 2:
            curPriority = "low";
            break;
          case 3:
            curPriority = "medium";
            break;
          case 4:
            curPriority = "high";
            break;
        }
      }
    }

    return curPriority;
  }

  getMarkerAdditionalStyles() {
    const priority = this.getPriority();
    let zIndex = 0,
      opacity = 1;
    switch (priority) {
      case "high":
        zIndex = 30;
        break;
      case "medium":
        zIndex = 20;
        break;
      case "low":
        zIndex = 10;
        break;
    }

    if (priority === "low" && this.props.zoomLevel < 12) {
      opacity = 0.2;
    }

    return {
      zIndex: zIndex,
      opacity: opacity,
    };
  }

  getPopupContent() {
    let priorities = {
      good: 0,
      low: 0,
      medium: 0,
      high: 0,
    };
    for (const annotation of this.props.structure.annotations) {
      switch (annotation.severity) {
        case 1:
          priorities.good++;
          break;
        case 2:
          priorities.low++;
          break;
        case 3:
          priorities.medium++;
          break;
        case 4:
          priorities.high++;
          break;
      }
    }

    let content = '<div class="popup map-popup inspection-popup">';
    content +=
      '<div class="map-popup-header"><h5>' +
      this.props.structure.identifier +
      "</h5><p>Photos: " +
      "0" /*this.props.structure.photos.length*/ +
      "</p></div>";

    content += '<ul class="annotations-list">';
    if (priorities.high) {
      content += '<li class="priority-high">High: ' + priorities.high + "</li>";
    }
    if (priorities.medium) {
      content +=
        '<li class="priority-medium">Medium: ' + priorities.medium + "</li>";
    }
    if (priorities.low) {
      content += '<li class="priority-low">Low: ' + priorities.low + "</li>";
    }
    content += "</ul>";

    content += '<div class="photos-list">';
    if (this.props.structure.photos) {
      this.props.structure.photos.forEach((photo, i) => {
        if (i < 3) {
          content +=
            '<a href="' +
            detectEnvironment() +
            photo.full_image +
            '" data-fancybox="n' +
            this.props.structure.identifier +
            '" data-src="' +
            detectEnvironment() +
            photo.full_image +
            '" class="fancybox"><img src="' +
            photo.preview_image +
            '"></a>';
        } else {
          content +=
            '<div data-fancybox="n' +
            this.props.structure.identifier +
            '" data-src="' +
            detectEnvironment() +
            photo.full_image +
            '" data-thumb="' +
            photo.preview_image +
            '" class="d-none"></div>';
        }
      });
    }
    content += "</div>";
    content += "</div>";

    return content;
  }

  render() {
    if (!this.state.isTabActive) {
      return null; // Don't render the component when the tab is inactive
    }
    const priority = this.getPriority();
    if (!priority) {
      return;
    }

    const popupContent = this.getPopupContent();

    return (
      <>
        <Marker
          longitude={this.props.structure.coordinates[1]}
          latitude={this.props.structure.coordinates[0]}
          onClick={this.togglePopup}
          style={this.getMarkerAdditionalStyles()}
        >
          <div className={"map-icon-static priority-" + priority}>
            <div>
              <div></div>
            </div>
          </div>
        </Marker>
        {this.props.showPopup && (
          <Popup
            longitude={this.props.structure.coordinates[1]}
            latitude={this.props.structure.coordinates[0]}
            anchor="bottom"
            onClose={this.togglePopup}
            style={{ zIndex: 50 }}
          >
            {parse(popupContent)}
          </Popup>
        )}
      </>
    );
  }
}

export default MarkerObject;

import { createContext } from 'react';

const defaultAuthState = {
    id: null,
    email: null,

    role: 'guest', // or 'customer', 'admin'
    permissions: [],
    isGuest: true
};
const AuthContext = createContext(defaultAuthState);

export default AuthContext;